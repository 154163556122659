import React from 'react';
import { translate as withTranslate, FunctionField } from 'react-admin';
import PropTypes from 'prop-types';

const StatusField = (props) => {
  const { translate, ...rest } = props;
  return (
    <FunctionField
      render={(record) => (
        translate(`resources.orders.statuses.${Object.keys(record.status)[0]}`)
      )}
      {...rest}
    />
  );
};

StatusField.propTypes = {
  translate: PropTypes.func.isRequired,
};

export default withTranslate(StatusField);
