import React from 'react';
import * as PropTypes from 'prop-types';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import { Card, CardContent, CardHeader } from '@material-ui/core';
import styles from './dashboardelement.module.css';

const DashboardElement = ({ title, children }) => (
  <Card>
    <CardHeader title={title} />
    <CardContent className={styles.content}>
      {children}
    </CardContent>
  </Card>
);

DashboardElement.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

DashboardElement.defaultProps = {
  title: null,
  children: () => null,
};

export default DashboardElement;
