import React from 'react';
import * as PropTypes from 'prop-types';
import { FunctionField, translate as withTranslate } from 'react-admin';

const BillingIntervalField = (props) => {
  const { translate, ...rest } = props;
  return (
    <FunctionField
      render={(record, source) => (record[source] ? translate(`resources.restaurateurs.values.${record[source]}`) : '')}
      {...rest}
    />
  );
};

BillingIntervalField.propTypes = {
  translate: PropTypes.func.isRequired,
  addLabel: PropTypes.bool,
};

BillingIntervalField.defaultProps = {
  addLabel: true,
};

export default withTranslate(BillingIntervalField);
