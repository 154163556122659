import React from 'react';
import * as PropTypes from 'prop-types';
import {
  Show,
  TabbedShowLayout,
  NumberField,
  ReferenceField,
  ReferenceManyField,
  Datagrid,
  TextField,
  ShowButton,
  Pagination,
  Tab,
  translate as withTranslate,
} from 'react-admin';
import orderFields from '../order/fields';
import Actions from './actions';

const CustomerShow = ({ translate, ...props }) => {
  const { id } = props;
  const customerId = id.split('_')[1];

  return (
    <Show actions={<Actions />} {...props}>
      <TabbedShowLayout>
        <Tab label={translate('resources.customers.tabs.general')}>
          <ReferenceField source="user" reference="users" link="show">
            <TextField source="email" />
          </ReferenceField>
          <NumberField source="salesCount" />
          <NumberField source="salesSum" options={{ style: 'currency', currency: 'EUR' }} />
        </Tab>
        <Tab label={translate('resources.customers.tabs.orders')}>
          <ReferenceManyField
            filter={{ client: customerId }}
            pagination={<Pagination />}
            source="user"
            reference="orders"
            target="client"
            linkType="show"
            addLabel={false}
          >
            <Datagrid rowClick="show">
              {orderFields(false)}
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

CustomerShow.propTypes = {
  id: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
};

export default withTranslate(CustomerShow);
