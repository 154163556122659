import React from 'react';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import { Typography } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import { translate as withTranslate } from 'react-admin';

const MetricField = (props) => {
  const { record: { value, originId }, translate, unit } = props;
  return (
    <div>
      <Typography variant="body1">{translate(`resources.metrics.${originId}.title`)}</Typography>
      <Typography variant="body2">{`${value} ${unit}`}</Typography>
    </div>
  );
};

MetricField.propTypes = {
  record: PropTypes.shape({
    value: PropTypes.number.isRequired,
    originId: PropTypes.string.isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  unit: PropTypes.string,
};

MetricField.defaultProps = {
  unit: '',
};

export default withTranslate(MetricField);
