import React from 'react';
import { FunctionField } from 'react-admin';

const PercentField = (props) => (
  <FunctionField render={(record, source) => `${(record[source] * 100).toFixed(1)}%`} {...props} />
);

PercentField.defaultProps = {
  addLabel: true,
};

export default PercentField;
