import React from 'react';
import * as PropTypes from 'prop-types';
import {
  Show,
  TabbedShowLayout,
  Tab,
  translate as withTranslate,
  ReferenceManyField,
  Datagrid,
  Pagination,
  ShowButton,
  ReferenceField,
  TextField, ImageField, NumberField, FunctionField,
} from 'react-admin';
import managerFields from '../manager/fields';
import fridgeFields from '../fridge/fields';
import delivererFields from '../deliverer/fields';
import fields from './fields';

const RestaurateurShow = (props) => {
  const { translate, ...rest } = props;
  return (
    <Show {...rest}>
      <TabbedShowLayout>
        <Tab label={translate('resources.restaurateurs.tabs.general')}>
          {fields}
          <ImageField key="logo.src" source="logo.src" />
          <TextField source="color" />
          <TextField source="iban" />
          <TextField source="bic" />
          <TextField source="businessIdentifier" />
          <TextField source="streetLine1" />
          <TextField source="streetLine2" />
          <TextField source="postalCode" />
          <TextField source="city" />
          <TextField source="region" />
          <ReferenceField key="country" allowEmpty source="country" reference="countries" link={false}>
            <TextField source="name" />
          </ReferenceField>
          <TextField source="phone1" />
          <TextField source="phone2" />
          <TextField source="fax" />
          <TextField source="email" />
          <TextField source="web" />
          <TextField source="piElectroniqueScorePi" />
          <TextField source="adyenAccountId" />
          <TextField source="paygreenShopId" />
          <TextField source="edenredMerchantId" />
          <NumberField source="mcc" />
          <FunctionField source="annualProcessingVolume" render={(record) => record.annualProcessingVolume / 100} />
          <FunctionField source="averageTransactionValue" render={(record) => record.averageTransactionValue / 100} />
          <FunctionField source="highestTransactionValue" render={(record) => record.highestTransactionValue / 100} />
        </Tab>
        <Tab label={translate('resources.restaurateurs.tabs.managers')}>
          <ReferenceManyField
            reference="managers"
            target="restaurateur.slug"
            source="slug"
            pagination={<Pagination />}
            addLabel={false}
          >
            <Datagrid>
              {managerFields}
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label={translate('resources.restaurateurs.tabs.fridges')}>
          <ReferenceManyField
            reference="fridges"
            target="restaurateur.slug"
            source="slug"
            pagination={<Pagination />}
            addLabel={false}
          >
            <Datagrid>
              {fridgeFields}
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label={translate('resources.restaurateurs.tabs.deliverers')}>
          <ReferenceManyField
            reference="deliverers"
            target="restaurateur.slug"
            source="slug"
            pagination={<Pagination />}
            addLabel={false}
          >
            <Datagrid>
              {delivererFields}
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

RestaurateurShow.propTypes = {
  translate: PropTypes.func.isRequired,
};

export default withTranslate(RestaurateurShow);
