import React from 'react';
import {
  Datagrid, NumberField, Filter, DateInput, DateField, List,
} from 'react-admin';

const TemperatureReadingListFilter = (props) => (
  <Filter {...props}>
    <DateInput source="since" alwaysOn />
  </Filter>
);

export const TemperatureReadingDataGrid = (props) => (
  <Datagrid {...props}>
    <NumberField source="temperature" />
    <DateField source="createdAt" showTime />
  </Datagrid>
);

const TemperatureReadingList = (props) => (
  <List bulkActionButtons={false} exporter={false} filters={<TemperatureReadingListFilter />} {...props}>
    <TemperatureReadingDataGrid />
  </List>
);

export default TemperatureReadingList;
