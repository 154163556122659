import React from 'react';
import {
  Show, SimpleShowLayout, ReferenceField, TextField,
} from 'react-admin';

const ManagerShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <ReferenceField source="targetUser" reference="users" link="show">
        <TextField source="email" />
      </ReferenceField>
      <ReferenceField source="restaurateur" reference="restaurateurs" link="show">
        <TextField source="name" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
);

export default ManagerShow;
