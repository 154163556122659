import React, { useRef } from 'react';
import {
  TextInput, NumberInput, required, ImageInput, ImageField, BooleanInput, Labeled, maxValue, minValue,
} from 'react-admin';
import { Editor } from '@tinymce/tinymce-react';
import { useField } from 'react-final-form';
import PropTypes from 'prop-types';
import PercentInput from '../common/PercentInput';
import { NutriScoreInput } from './NutriScore';

const EditorInput = ({ source, label }) => {
  const {
    input: { onChange, value },
  } = useField(source);

  const editorRef = useRef();

  return (
    <Labeled label={label}>
      <Editor
        onInit={(evt, editor) => { editorRef.current = editor; }}
        initialValue={value || ''}
        onFocus={() => {
          onChange(`${value} `);
        }}
        onBlur={(e) => {
          onChange(e.target.getContent());
        }}
        tinymceScriptSrc={`${window.location.protocol}//${window.location.host}/tinymce/tinymce.min.js`}
        init={{
          height: 300,
          width: '100%',
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
          ],
          toolbar: 'undo redo | formatselect | '
            + 'bold italic forecolor backcolor | alignleft aligncenter '
            + 'alignright alignjustify | bullist numlist outdent indent | '
            + 'table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | '
            + 'removeformat | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        }}
      />
    </Labeled>
  );
};

EditorInput.propTypes = {
  source: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default [
  <TextInput key="name" source="name" validate={required()} />,
  <EditorInput key="description" source="description" label="resources.products.fields.description" />,
  <ImageInput key="picture" source="picture" accept="image/*">
    <ImageField source="src" title="title" />
  </ImageInput>,
  <PercentInput key="tax" source="tax" validate={required()} />,
  <NumberInput key="price" source="price" validate={required()} />,
  <NumberInput key="timedReducedPrice" source="timedReducedPrice" />,
  <NumberInput key="noWastePrice" source="noWastePrice" />,
  <NutriScoreInput key="nutriScore" source="nutriScore" />,
  <BooleanInput key="eligibleTitresRestaurant" source="eligibleTitresRestaurant" parse={(value) => !!value} />,
  <NumberInput max="365" min="0" key="expirationDelay" source="expirationDelay" validate={[required(), maxValue(1095), minValue(0)]} />,
];
