import React from 'react';
import { AppBar as ReactAdminAppBar } from 'react-admin';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import Typography from '@material-ui/core/Typography';
import styles from './appbar.module.css';
import Logo from './Logo';
import UserMenu from './UserMenu';

const AppBar = (props) => (
  <ReactAdminAppBar userMenu={<UserMenu />} {...props}>
    <Typography
      variant="h6"
      color="inherit"
      className={styles.title}
      id="react-admin-title"
    />
    <Logo className={styles.logo} />
    <span className={styles.spacer} />
  </ReactAdminAppBar>
);

export default AppBar;
