/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  hydraDataProvider as baseHydraDataProvider,
  fetchHydra as baseFetchHydra,
  useIntrospection,
} from '@api-platform/admin';
import { parseHydraDocumentation } from '@api-platform/api-doc-parser';
import { entrypoint } from '../config';
import fileToBase64 from '../converter/fileToBase64';

const convertFileParams = async (params, key) => {
  if (params.data[key].rawFile instanceof File) {
    const b64Picture = await fileToBase64(params.data[key].rawFile);
    return { ...params, data: { ...params.data, [key]: b64Picture } };
  }

  return { ...params, data: { ...params.data, [key]: params.data[key].src } };
};

const convertParams = async (type, resource, params) => {
  if (!['update', 'create'].includes(type)) {
    if (params?.pagination?.perPage) {
      const filter = {
        perPage: params.pagination?.perPage,
        ...params.filter,
      };
      return {
        ...params,
        filter,
      };
    }
    return params;
  }

  if (resource === 'products' && params.data.picture) {
    return convertFileParams(params, 'picture');
  }
  if (resource === 'restaurateurs' && params.data.logo) {
    return convertFileParams(params, 'logo');
  }
  if (resource === 'fridges' && params.data.logo) {
    const fridgeParams = { ...params };
    if (!fridgeParams.data.customThemePrimaryColor) {
      fridgeParams.data.themePrimaryColor = null;
    }
    if (!fridgeParams.data.customThemeBodyBackgroundColor) {
      fridgeParams.data.themeBodyBackgroundColor = null;
    }
    if (!fridgeParams.data.customThemeHeaderBackgroundColor) {
      fridgeParams.data.themeHeaderBackgroundColor = null;
    }
    return convertFileParams(fridgeParams, 'logo');
  }

  return params;
};

const convertFileResponse = (response, type, key) => {
  switch (type) {
    case 'getOne':
      return { ...response, data: { ...response.data, [key]: { src: response.data[key] } } };
    case 'getList':
    case 'getMany':
      return { ...response, data: response.data.map((product) => ({ ...product, [key]: { src: product[key] } })) };
    default:
      return response;
  }
};

const convertResponse = (type, resource, response) => {
  if (resource === 'products') {
    return convertFileResponse(response, type, 'picture');
  }
  if (resource === 'restaurateurs') {
    return convertFileResponse(response, type, 'logo');
  }
  if (resource === 'fridges') {
    response.data.customThemePrimaryColor = !!response.data.themePrimaryColor;
    response.data.customThemeBodyBackgroundColor = !!response.data.themeBodyBackgroundColor;
    response.data.customThemeHeaderBackgroundColor = !!response.data.themeHeaderBackgroundColor;
    return convertFileResponse(response, type, 'logo');
  }

  return response;
};

const getHeaders = () => (localStorage.getItem('token') ? {
  Authorization: `Bearer ${localStorage.getItem('token')}`,
} : {});

const RedirectToLogin = () => {
  const introspect = useIntrospection();

  if (localStorage.getItem('token')) {
    introspect();
    return <></>;
  }
  return <Redirect to="/login" />;
};

const apiDocumentationParser = async () => {
  try {
    const { api } = await parseHydraDocumentation(entrypoint, { headers: getHeaders });
    return { api };
  } catch (result) {
    const { api, response, status } = result;

    if (status !== 401 || !response) {
      throw result;
    }

    // Prevent infinite loop if the token is expired
    localStorage.removeItem('token');

    window.location.href = '/';

    return {
      api,
      response,
      status,
      customRoutes: [
        <Route key="/login" path="/login" component={RedirectToLogin} />,
      ],
    };
  }
};

const fetchHydra = (url, options = {}) => baseFetchHydra(url, {
  ...options,
  credentials: 'include',
  headers: getHeaders,
});

const provider = baseHydraDataProvider({
  entrypoint,
  httpClient: fetchHydra,
  apiDocumentationParser,
  useEmbedded: true,
  useMercure: false,
});

export default new Proxy(provider, {
  get: (target, name) => {
    if (typeof name === 'symbol') {
      return;
    }

    // eslint-disable-next-line consistent-return
    return async (resource, params) => {
      const convertedParams = await convertParams(name, resource, params);
      const response = await provider[name](resource, convertedParams);
      return convertResponse(name, resource, response);
    };
  },
});
