import React from 'react';
import {
  Edit, SimpleForm, translate as withTranslate,
} from 'react-admin';
import * as PropTypes from 'prop-types';
import inputs from './inputs';

const DelivererEdit = (props) => {
  const { permissions, translate } = props;
  return (
    <Edit {...props}>
      <SimpleForm redirect="show">
        {inputs(translate, permissions)}
      </SimpleForm>
    </Edit>
  );
};

DelivererEdit.propTypes = {
  permissions: PropTypes.shape({
    roles: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }),
  translate: PropTypes.func.isRequired,
};

DelivererEdit.defaultProps = {
  permissions: null,
};

export default withTranslate(DelivererEdit);
