import React from 'react';
import {
  Show, TabbedShowLayout, Tab, translate as withTranslate, ReferenceManyField, Datagrid, ShowButton, Pagination,
} from 'react-admin';
import * as PropTypes from 'prop-types';
import fields from './fields';
import orderFields from '../order/fields';

const UserShow = ({ translate, ...props }) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label={translate('resources.users.tabs.general')}>
        {fields}
      </Tab>
      <Tab label={translate('resources.users.tabs.orders')}>
        <ReferenceManyField
          pagination={<Pagination />}
          source="id"
          reference="orders"
          target="client"
          link="show"
          addLabel={false}
        >
          <Datagrid rowClick="show">
            {orderFields(false)}
            <ShowButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

UserShow.propTypes = {
  translate: PropTypes.func.isRequired,
};

export default withTranslate(UserShow);
