import React from 'react';
import {
  Edit, SimpleForm, translate as withTranslate,
} from 'react-admin';
import * as PropTypes from 'prop-types';
import inputs from './inputs';

const ManagerEdit = ({ translate, ...props }) => (
  <Edit {...props}>
    <SimpleForm redirect="show">
      {inputs(translate)}
    </SimpleForm>
  </Edit>
);

ManagerEdit.propTypes = {
  translate: PropTypes.func.isRequired,
};

export default withTranslate(ManagerEdit);
