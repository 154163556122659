import React from 'react';
import {
  Create, ReferenceInput, SimpleForm, AutocompleteInput, TextInput, BooleanInput, required,
} from 'react-admin';

const FridgeCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="show">
      <ReferenceInput
        source="restaurateur"
        reference="restaurateurs"
        filterToQuery={(searchText) => ({ name: searchText })}
        validate={required()}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <TextInput source="name" validate={required()} />
      <TextInput source="slug" validate={required()} />
      <TextInput source="piElectroniqueVendingId" parse={(value) => value || null} />
      <BooleanInput source="enabled" validate={required()} />
    </SimpleForm>
  </Create>
);

export default FridgeCreate;
