import React from 'react';
import { Edit, SimpleForm } from 'react-admin';
import PaymentStatusInput from './PaymentStatusInput';

const InvoiceEdit = (props) => (
  <Edit {...props}>
    <SimpleForm redirect="show">
      <PaymentStatusInput source="paymentStatus" />
    </SimpleForm>
  </Edit>
);

export default InvoiceEdit;
