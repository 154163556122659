import React from 'react';
import { List, Datagrid, ShowButton } from 'react-admin';
import * as PropTypes from 'prop-types';
import { get } from 'lodash';
import fields from './fields';
import PaidActionButton from './PaidActionButton';
import GenerateSepaXmlActionButton from './GenerateSepaXmlActionButton';
import { ROLE_ADMIN } from '../providers/authProvider';

const BulkActionButtons = (props) => (
  <>
    <PaidActionButton {...props} />
    <GenerateSepaXmlActionButton {...props} />
  </>
);

const InvoiceList = (props) => {
  const { permissions } = props;
  return (
    <List
      sort={{ field: 'date', order: 'desc' }}
      bulkActionButtons={get(permissions, 'roles', []).includes(ROLE_ADMIN)
        ? <BulkActionButtons permissions={permissions} />
        : false}
      exporter={false}
      {...props}
    >
      <Datagrid rowClick="show">
        {fields(permissions)}
        <ShowButton />
      </Datagrid>
    </List>
  );
};

InvoiceList.propTypes = {
  permissions: PropTypes.shape({
    roles: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }),
};

InvoiceList.defaultProps = {
  permissions: null,
};

export default InvoiceList;
