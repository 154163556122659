import { createElement } from 'react';
import * as PropTypes from 'prop-types';

const DashboardList = ({
  resource, list, match, location,
}) => createElement(list, {
  resource,
  match,
  location,
  basePath: `/${resource}`,
  hasCreate: false,
  hasEdit: false,
  hasList: false,
  hasShow: false,
  actions: null,
  title: ' ',
});

DashboardList.propTypes = {
  resource: PropTypes.string.isRequired,
  list: PropTypes.func.isRequired,
  match: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
};

export default DashboardList;
