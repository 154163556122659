import React from 'react';
import {
  Show, SimpleShowLayout, ReferenceField, TextField,
} from 'react-admin';
import FridgeMediaField from './FridgeMediaField';

const FridgeMediaShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <ReferenceField source="fridge" reference="fridges" link="show">
        <TextField source="slug" />
      </ReferenceField>
      <FridgeMediaField />
    </SimpleShowLayout>
  </Show>
);

export default FridgeMediaShow;
