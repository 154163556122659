import React from 'react';
import {
  List, Datagrid, ShowButton, Filter, TextInput, TextField, ImageField, NumberField,
} from 'react-admin';
import * as PropTypes from 'prop-types';

const ProductListFilters = (props) => (
  <Filter {...props}>
    <TextInput source="name" alwaysOn />
  </Filter>
);

const ProductList = (props) => (
  <List filters={<ProductListFilters />} exporter={false} {...props}>
    <Datagrid rowClick="show">
      <TextField key="name" source="name" />
      <ImageField key="picture.src" source="picture.src" />
      <NumberField key="price" source="price" options={{ style: 'currency', currency: 'EUR' }} />
      <NumberField key="expirationDelay" source="expirationDelay" />
      <ShowButton />
    </Datagrid>
  </List>
);

ProductList.propTypes = {
  permissions: PropTypes.shape({
    manager: PropTypes.shape({
      restaurateur: PropTypes.shape({
        piElectroniqueSupport: PropTypes.bool.isRequired,
      }),
    }),
  }),
};

ProductList.defaultProps = {
  permissions: null,
};

export default ProductList;
