import React from 'react';
import {
  List, Datagrid, ShowButton,
} from 'react-admin';
import fields from './fields';

const RestaurateurList = (props) => (
  <List exporter={false} {...props}>
    <Datagrid rowClick="show">
      {fields}
      <ShowButton />
    </Datagrid>
  </List>
);

export default RestaurateurList;
