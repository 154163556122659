import React from 'react';
import {
  ReferenceField, TextField, DateField, NumberField,
} from 'react-admin';
import { get } from 'lodash';
import PaymentStatusField from './PaymentStatusField';
import DatePeriodField from './DatePeriodField';
import DownloadButton from './DownloadButton';
import { ROLE_ADMIN } from '../providers/authProvider';

export default (permissions) => [
  get(permissions, 'roles', []).includes(ROLE_ADMIN) && (
    <ReferenceField
      key="restaurateur"
      source="restaurateur"
      reference="restaurateurs"
      link="show"
      sortBy="restaurateur.name"
    >
      <TextField source="name" />
    </ReferenceField>
  ),
  get(permissions, 'roles', []).includes(ROLE_ADMIN) && (
    <PaymentStatusField key="paymentStatus" source="paymentStatus" />
  ),
  <DateField key="date" source="date" />,
  <DatePeriodField
    key="datePeriod"
    startSource="dateStart"
    endSource="dateEnd"
    source="datePeriod"
    sortBy="dateStart"
  />,
  <TextField key="number" source="number" />,
  <NumberField key="total" source="total" options={{ style: 'currency', currency: 'EUR' }} />,
  <DownloadButton key="url" source="url" />,
];
