import React from 'react';
import { TextField, NumberField, DateField } from 'react-admin';
import StatusField from './StatusField';

export default (sortable = true) => [
  <DateField key="date" source="date" sortable={sortable} showTime />,
  <TextField key="fridgeName" source="fridgeName" sortable={sortable} />,
  <StatusField key="status" source="status" sortable={sortable} />,
  <NumberField key="total" source="total" options={{ style: 'currency', currency: 'EUR' }} sortable={false} />,
];
