import React from 'react';
import {
  List,
  Datagrid,
  ReferenceField,
  TextField,
  ShowButton,
} from 'react-admin';
import fields from './fields';

const ManagerList = (props) => (
  <List exporter={false} {...props}>
    <Datagrid rowClick="show">
      {fields}
      <ReferenceField source="restaurateur" reference="restaurateurs" link="show" sortBy="restaurateur.name">
        <TextField source="name" />
      </ReferenceField>
      <ShowButton />
    </Datagrid>
  </List>
);

export default ManagerList;
