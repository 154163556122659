import React from 'react';
import * as PropTypes from 'prop-types';
import { FunctionField, translate as withTranslate } from 'react-admin';

const StatusField = (props) => {
  const { target, translate, ...rest } = props;
  return (
    <FunctionField
      {...rest}
      render={(record) => (record[target]
        ? translate('resources.fridges.values.online')
        : translate('resources.fridges.values.offline'))}
    />
  );
};

StatusField.propTypes = {
  addLabel: PropTypes.bool,
  target: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
};

StatusField.defaultProps = {
  addLabel: true,
};

export default withTranslate(StatusField);
