import { AutocompleteInput, ReferenceInput, required } from 'react-admin';
import { get } from 'lodash';
import React from 'react';
import { ROLE_ADMIN } from '../providers/authProvider';
import { userOptionText } from '../user/fields';

export default (translate, permissions) => (
  <>
    <ReferenceInput
      source="targetUser"
      reference="users"
      filterToQuery={(searchText) => ({ email: searchText })}
      validate={required()}
    >
      <AutocompleteInput optionText={(user) => user && userOptionText(user, translate)} />
    </ReferenceInput>
    { get(permissions, 'roles', []).includes(ROLE_ADMIN) && (
      <ReferenceInput
        source="restaurateur"
        reference="restaurateurs"
        filterToQuery={(searchText) => ({ name: searchText })}
        validate={required()}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    ) }
  </>
);
