import React from 'react';
import {
  List, Datagrid, DateField, NumberField, Filter, DateInput,
} from 'react-admin';

const DailySalesReportListFilter = (props) => (
  <Filter {...props}>
    <DateInput source="date" alwaysOn />
  </Filter>
);

const DailySalesReportList = (props) => (
  <List bulkActionButtons={false} filters={<DailySalesReportListFilter />} exporter={false} {...props}>
    <Datagrid>
      <DateField source="date" />
      <NumberField source="total" options={{ style: 'currency', currency: 'EUR' }} />
    </Datagrid>
  </List>
);

export default DailySalesReportList;
