import React from 'react';
import {
  Button, useRefresh, useUpdate,
} from 'react-admin';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import PropTypes from 'prop-types';
import FridgeMediaField from './FridgeMediaField';

const PositionButton = (props) => {
  const { resource, record, direction } = props;
  const refresh = useRefresh();

  const [update, { loading }] = useUpdate();

  const onClick = () => {
    update(resource, record.id, { position: record.position + direction }, record,
      {
        returnPromise: true,
      }).then(() => {
      refresh();
    });
  };

  return (
    <Button onClick={onClick} disabled={loading}>
      {direction > 0 ? <ArrowDownward /> : <ArrowUpward />}
    </Button>
  );
};

PositionButton.propTypes = {
  resource: PropTypes.string,
  record: PropTypes.shape({
    id: PropTypes.string,
    position: PropTypes.number,
  }),
  direction: PropTypes.number.isRequired,
};

PositionButton.defaultProps = {
  resource: null,
  record: null,
};

const PositionButtons = (props) => (
  <div>
    <PositionButton direction={-1} {...props} />
    <PositionButton direction={+1} {...props} />
  </div>
);

export default [
  <FridgeMediaField key="media.src" source="media.src" sortable={false} />,
  <PositionButtons key="position" sortable={false} />,
];
