import React, { useMemo } from 'react';
import { Title, translate as withTranslate } from 'react-admin';
import * as PropTypes from 'prop-types';
import DashboardElement from './DashboardElement';
import DashboardShow from './DashboardShow';
import MetricShow from '../metric/Show';
import styles from './dashboard.module.css';

const AdminDashboard = ({ location, match, translate }) => {
  const metrics = useMemo(() => (
    <DashboardElement key="metrics" title={translate('resources.dashboard.elements.metrics.title')}>
      <DashboardShow
        location={location}
        match={match}
        resource="metrics"
        show={MetricShow('€')}
        id="/metrics/today_sales_sum"
      />
      <DashboardShow
        location={location}
        match={match}
        resource="metrics"
        show={MetricShow()}
        id="/metrics/today_client_count"
      />
    </DashboardElement>
  ), [location, match, translate]);

  return (
    <div className={styles.dashboard}>
      <Title title={translate('resources.dashboard.title')} />
      {metrics}
    </div>
  );
};

AdminDashboard.propTypes = {
  match: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
  translate: PropTypes.func.isRequired,
};

export default withTranslate(AdminDashboard);
