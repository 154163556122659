import React from 'react';
import * as PropTypes from 'prop-types';
import {
  translate as withTranslate,
} from 'react-admin';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import { CardActions, Button } from '@material-ui/core';

const adminEmail = 'sebastien@helpyourshelf.io';
const mailToValue = `mailto:${adminEmail}?subject=%5BD%C3%A9claration%20abus%20utilisateur%5D`;

const actions = (props) => {
  const { translate } = props;

  return (
    <CardActions>
      <Button variant="contained" href={mailToValue}>
        {translate('resources.customers.actions.reportAbuse')}
      </Button>
    </CardActions>
  );
};

actions.propTypes = {
  translate: PropTypes.func.isRequired,
};

export default withTranslate(actions);
