import React, { useState } from 'react';
import {
  Button, Confirm, showNotification as raShowNotification, translate as withTransalate,
} from 'react-admin';
// eslint-disable-next-line import/no-extraneous-dependencies
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { parse } from 'content-disposition';
import { entrypoint } from '../config';

const idToOriginId = (resource) => (id) => id.replace(`/${resource}/`, '');

const post = (ids) => fetch(`${entrypoint}/generate_sepa_xml`, {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/ld+json',
  },
  method: 'POST',
  body: JSON.stringify({ id: ids }),
  credentials: 'include',
});

const download = (blob, filename) => {
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // Manage IE11+ & Edge
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const fakeLink = document.createElement('a');
    fakeLink.style.display = 'none';
    document.body.appendChild(fakeLink);
    fakeLink.setAttribute('href', URL.createObjectURL(blob));
    fakeLink.setAttribute('download', filename);
    fakeLink.click();
  }
};

const GenerateSepaXmlActionButton = (props) => {
  const {
    selectedIds, showNotification, translate, resource,
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        label={translate('resources.invoices.actions.generate_sepa_xml_file')}
        onClick={() => setIsOpen(true)}
      />
      <Confirm
        isOpen={isOpen}
        title={translate('resources.invoices.actions.generate_sepa_xml_file')}
        content={translate('resources.invoices.actions.generate_sepa_xml_file_confirm')}
        onConfirm={async () => {
          try {
            const response = await post(selectedIds.map(idToOriginId(resource)));
            const blob = await response.blob();
            const { parameters: { filename } } = parse(
              response.headers.get('Content-Disposition') || 'attachment; filename=file.xml',
            );
            download(blob, filename);
          } catch (e) {
            showNotification('ra.message.error', 'warning');
          }
          setIsOpen(false);
        }}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
};

GenerateSepaXmlActionButton.propTypes = {
  selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  translate: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
  resource: PropTypes.string.isRequired,
};

export default connect(null, { showNotification: raShowNotification })(withTransalate(GenerateSepaXmlActionButton));
