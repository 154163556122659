import React from 'react';
import {
  List,
  Datagrid,
  ShowButton,
  Filter,
  TextInput,
} from 'react-admin';
import fields from './fields';

const UserListFilters = (props) => (
  <Filter {...props}>
    <TextInput source="email" alwaysOn />
    <TextInput source="nfc" />
    <TextInput source="pos" />
  </Filter>
);

const UserList = (props) => (
  <List filters={<UserListFilters />} exporter={false} {...props}>
    <Datagrid rowClick="show">
      {fields}
      <ShowButton />
    </Datagrid>
  </List>
);

export default UserList;
