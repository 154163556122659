import React from 'react';
import { ReferenceField } from 'react-admin';
import * as PropTypes from 'prop-types';

const MetricField = (props) => {
  const {
    record, source, children, ...rest
  } = props;
  record[source] = `/metrics/${source}_${record.originId}`;

  return (
    <ReferenceField source={source} reference="metrics" record={record} {...rest} link={false}>
      {children}
    </ReferenceField>
  );
};

MetricField.propTypes = {
  record: PropTypes.shape({
    originId: PropTypes.number.isRequired,
  }),
  source: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  addLabel: PropTypes.bool,
};

MetricField.defaultProps = {
  record: {
    originId: 0,
  },
  addLabel: true,
};

export default MetricField;
