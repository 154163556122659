import React from 'react';
import {
  Edit, SimpleForm, TextInput, BooleanInput, required,
} from 'react-admin';

const UserEdit = (props) => (
  <Edit {...props}>
    <SimpleForm redirect="show">
      <TextInput source="email" validate={required()} />
      <TextInput source="nfc" parse={(value) => value || null} />
      <BooleanInput source="enabled" validate={required()} />
    </SimpleForm>
  </Edit>
);

export default UserEdit;
