import React from 'react';
import {
  Edit, SimpleForm,
} from 'react-admin';
import inputs from './inputs';

const RestaurateurEdit = (props) => (
  <Edit {...props}>
    <SimpleForm redirect="show">
      {inputs}
    </SimpleForm>
  </Edit>
);

export default RestaurateurEdit;
