import React from 'react';
import {
  Create, SimpleForm, translate as withTranslate,
} from 'react-admin';
import * as PropTypes from 'prop-types';
import inputs from './inputs';

const DelivererCreate = (props) => {
  const { permissions, translate } = props;
  return (
    <Create {...props}>
      <SimpleForm redirect="show">
        {inputs(translate, permissions)}
      </SimpleForm>
    </Create>
  );
};

DelivererCreate.propTypes = {
  permissions: PropTypes.shape({
    roles: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }),
  translate: PropTypes.func.isRequired,
};

DelivererCreate.defaultProps = {
  permissions: null,
};

export default withTranslate(DelivererCreate);
