import React from 'react';
import {
  TextField,
  NumberField,
  Tab,
  translate as withTranslate,
} from 'react-admin';
import * as PropTypes from 'prop-types';

const OffersTab = (props) => {
  const { permissions, translate, ...rest } = props;

  return (
    <Tab {...rest} label={translate('resources.fridges.tabs.offers')}>
      <TextField source="reductionStart" label={translate('resources.fridges.fields.reductionStartHour', 1)} />
      <TextField source="reductionEnd" label={translate('resources.fridges.fields.reductionEndHour', 1)} />
      <NumberField
        source="hoursBeforeNoWasteReduction"
        label={translate('resources.fridges.fields.noWasteOfferDelay', 1)}
      />
    </Tab>
  );
};

OffersTab.propTypes = {
  permissions: PropTypes.shape({
    roles: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
};

export default withTranslate(OffersTab);
