import frenchMessages from 'ra-language-french';
import englishMessages from 'ra-language-english';
import { merge } from 'lodash';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { resolveBrowserLocale } from 'react-admin';
import * as i18n from '../i18n';
import { defaultLocale } from '../config';

export default polyglotI18nProvider(
  (locale) => merge({ en: englishMessages, fr: frenchMessages }, i18n)[locale], resolveBrowserLocale(defaultLocale),
);
