import React from 'react';
import { FunctionField, translate as withTranslate, ChipField } from 'react-admin';
import PropTypes from 'prop-types';

const RolesField = (props) => {
  const { translate, ...rest } = props;
  return (
    <FunctionField
      render={({ roles }) => roles.map((role) => {
        if (role !== 'ROLE_USER') {
          return <ChipField key={role} record={{ role: translate(`resources.users.roles.${role}`) }} source="role" />;
        }
        return null;
      })}
      {...rest}
    />
  );
};

RolesField.propTypes = {
  translate: PropTypes.func.isRequired,
};

const TranslatedRolesField = withTranslate(RolesField);

export default TranslatedRolesField;
