import React from 'react';
import {
  required, AutocompleteInput, FileInput, FileField, ReferenceInput, NumberInput,
} from 'react-admin';

export default (translate, fridge) => [
  <FileInput
    key="file"
    source="file"
    accept="image/*, video/*"
    maxSize={8000000}
    multiple={false}
  >
    <FileField source="media.src" title="title" />
  </FileInput>,

  (!fridge && (
    <ReferenceInput
      key="fridge"
      source="fridge"
      reference="fridges"
      filterToQuery={(searchText) => ({ slug: searchText })}
      validate={required()}
    >
      <AutocompleteInput optionText={(f) => f && f.slug} />
    </ReferenceInput>
  )),

  <NumberInput key="position" source="position" />,
];
