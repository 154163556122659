import React from 'react';
import {
  Create, SimpleForm, translate as withTranslate,
} from 'react-admin';
import * as PropTypes from 'prop-types';
import inputs from './inputs';

const ManagerCreate = ({ translate, ...props }) => (
  <Create {...props}>
    <SimpleForm redirect="show">
      {inputs(translate)}
    </SimpleForm>
  </Create>
);

ManagerCreate.propTypes = {
  translate: PropTypes.func.isRequired,
};

export default withTranslate(ManagerCreate);
