import React from 'react';
import { TextField, DateField } from 'react-admin';
import PercentField from '../common/PercentField';
import BillingIntervalField from './BillingIntervalField';

export default [
  <TextField key="name" source="name" />,
  <TextField key="slug" source="slug" />,
  <PercentField key="commissionRate" source="commissionRate" />,
  <BillingIntervalField key="billingInterval" source="billingInterval" />,
  <DateField key="createdAt" source="createdAt" />,
];
