/* eslint-disable max-len */
import React from 'react';
import styles from '../common/menuicon.module.css';

const Icon = () => (
  <svg
    className={styles.MenuIcon}
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 463 463"
    style={{ enableBackground: 'new 0 0 463 463' }}
    xmlSpace="preserve"
  >
    <g transform="translate(0 -540.36)">
      <g>
        <g>
          <path d="M359.5,540.36h-256c-4.1,0-7.5,3.4-7.5,7.5v423.1c0,4.1,3.4,7.5,7.5,7.5h5.3l23.4,22.8c1.4,1.4,3.3,2.1,5.2,2.1h188.2c2,0,3.8-0.8,5.2-2.1l23.4-22.8h5.3c4.1,0,7.5-3.4,7.5-7.5v-423.1C367,543.76,363.6,540.36,359.5,540.36z M322.5,988.36h-182l-10.2-9.9h202.4L322.5,988.36z M352,963.46H111v-242.6h241V963.46z M352,705.86H111v-150.5h241V705.86z" />
          <rect x="317.4" y="647.458" width="15" height="32.4" />
          <rect x="317.4" y="746.958" width="15" height="32.4" />
        </g>
      </g>
    </g>
  </svg>
);

export default Icon;
