import React from 'react';
import {
  Create, SimpleForm,
} from 'react-admin';
import inputs from './inputs';

const RestaurateurCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="show">
      {inputs}
    </SimpleForm>
  </Create>
);

export default RestaurateurCreate;
