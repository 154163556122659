import React, { useMemo } from 'react';
import { Title, translate as withTranslate } from 'react-admin';
import * as PropTypes from 'prop-types';
import DashboardElement from './DashboardElement';
import DashboardShow from './DashboardShow';
import MetricShow from '../metric/Show';
import DashboardList from './DashboardList';
import { TodayOrderList } from '../order/List';
import styles from './dashboard.module.css';
import { TodayProductOverviewList } from '../product_overview/List';
import { TodaySaleOverviewList } from '../sale_overview/List';

const RestaurateurDashboard = ({ location, match, translate }) => {
  const metrics = useMemo(() => (
    <DashboardElement key="metrics" title={translate('resources.dashboard.elements.metrics.title')}>
      <DashboardShow
        location={location}
        match={match}
        resource="metrics"
        show={MetricShow('€')}
        id="/metrics/today_sales_sum"
      />
      <DashboardShow
        location={location}
        match={match}
        resource="metrics"
        show={MetricShow()}
        id="/metrics/today_client_count"
      />
    </DashboardElement>
  ), [location, match, translate]);

  const productOverviews = useMemo(() => (
    <DashboardElement key="product_overviews" title={translate('resources.dashboard.elements.product_overviews.title')}>
      <DashboardList match={match} list={TodayProductOverviewList} resource="product_overviews" location={location} />
    </DashboardElement>
  ), [location, match, translate]);

  const saleOverviews = useMemo(() => (
    <DashboardElement key="sale_overviews" title={translate('resources.dashboard.elements.sale_overviews.title')}>
      <DashboardList match={match} list={TodaySaleOverviewList} resource="sale_overviews" location={location} />
    </DashboardElement>
  ), [location, match, translate]);

  const orders = useMemo(() => (
    <DashboardElement key="orders" title={translate('resources.dashboard.elements.orders.title')}>
      <DashboardList match={match} list={TodayOrderList} resource="orders" location={location} />
    </DashboardElement>
  ), [location, match, translate]);

  return (
    <div className={styles.dashboard}>
      <Title title={translate('resources.dashboard.title')} />
      {metrics}
      {productOverviews}
      {saleOverviews}
      {orders}
    </div>
  );
};

RestaurateurDashboard.propTypes = {
  match: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
  translate: PropTypes.func.isRequired,
};

export default withTranslate(RestaurateurDashboard);
