import React from 'react';
import { FunctionField, translate as withTranslate } from 'react-admin';
import * as PropTypes from 'prop-types';
import get from 'lodash/get';

const toLocaleStringSupportsLocales = (() => {
  try {
    new Date().toLocaleString('i');
  } catch (error) {
    return error instanceof RangeError;
  }
  return false;
})();

const dateToString = (date, showTime, locales, options) => (toLocaleStringSupportsLocales
  ? date[showTime ? 'toLocaleString' : 'toLocaleDateString'](locales, options)
  : date[showTime ? 'toLocaleString' : 'toLocaleDateString']());

const DatePeriodField = (props) => {
  const {
    startSource, endSource, showTime, record, locales, options, translate, ...rest
  } = props;
  const startValue = get(record, startSource);
  const endValue = get(record, endSource);
  const dateStart = `${translate('resources.invoices.period.from')} ${(startValue
    ? dateToString(startValue instanceof Date ? startValue : new Date(startValue), showTime)
    : '...')}`;
  const dateEnd = `${translate('resources.invoices.period.to')} ${(endValue
    ? dateToString(endValue instanceof Date ? endValue : new Date(endValue), showTime)
    : '...')}`;
  return (
    <FunctionField {...rest} render={() => `${dateStart} ${dateEnd}`} />
  );
};

DatePeriodField.propTypes = {
  startSource: PropTypes.string.isRequired,
  endSource: PropTypes.string.isRequired,
  locales: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  options: PropTypes.shape({}),
  showTime: PropTypes.bool,
  addLabel: PropTypes.bool,
  record: PropTypes.shape({}),
  translate: PropTypes.func.isRequired,
};

DatePeriodField.defaultProps = {
  locales: [],
  options: {},
  showTime: false,
  addLabel: true,
  record: {},
};

export default withTranslate(DatePeriodField);
