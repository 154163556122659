import React from 'react';
import * as PropTypes from 'prop-types';
import { Title, translate as withTranslate } from 'react-admin';
import { get } from 'lodash';
import { ROLE_ADMIN, ROLE_RESTAURATEUR } from '../providers/authProvider';
import AdminDashboard from './AdminDashboard';
import RestaurateurDashboard from './RestaurateurDashboard';

const Dashboard = ({
  location, match, translate, permissions,
}) => {
  const roles = get(permissions, 'roles', []);

  if (roles.length === 0) {
    return <Title title={translate('ra.page.dashboard')} />;
  }

  if (roles.includes(ROLE_ADMIN)) {
    return <AdminDashboard match={match} location={location} />;
  }

  if (roles.includes(ROLE_RESTAURATEUR)) {
    return <RestaurateurDashboard match={match} location={location} />;
  }

  return null;
};

Dashboard.propTypes = {
  match: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
  translate: PropTypes.func.isRequired,
  permissions: PropTypes.shape({
    roles: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }),
};

Dashboard.defaultProps = {
  permissions: null,
};

export default withTranslate(Dashboard);
