/* eslint-disable max-len */
import React from 'react';
import * as PropTypes from 'prop-types';

const Logo = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="300px" height="216px" viewBox="0 0 3000 2160" preserveAspectRatio="xMidYMid meet">
    <g id="layer101" fill="#fec707" stroke="none">
      <path d="M36 2144 c-3 -9 -6 -92 -6 -185 0 -168 0 -170 25 -193 55 -51 196 -72 275 -40 36 15 44 15 87 0 102 -35 217 -7 256 62 11 19 22 61 25 98 l5 65 22 -21 c27 -25 66 -37 156 -46 73 -7 83 -18 38 -41 -32 -17 -65 -16 -124 2 -56 16 -70 10 -80 -36 -14 -64 47 -99 173 -99 121 0 200 45 217 121 l7 32 13 -29 c31 -73 121 -124 221 -124 93 0 134 28 134 89 0 48 -18 65 -54 51 -85 -32 -156 11 -156 95 0 78 62 120 142 96 35 -11 42 -10 59 5 19 17 19 12 19 -229 l0 -247 64 0 c79 0 96 17 96 95 l0 50 83 0 c97 0 138 19 168 77 16 32 19 60 19 203 l0 165 -70 0 c-58 0 -71 -3 -80 -19 -5 -11 -10 -76 -10 -145 0 -135 -7 -156 -55 -156 -55 0 -55 0 -55 166 l0 154 -69 0 c-49 0 -72 -4 -81 -15 -12 -14 -15 -14 -30 0 -13 11 -42 15 -127 15 -106 0 -111 -1 -151 -30 -23 -16 -50 -47 -61 -67 l-20 -38 -1 42 c0 36 -5 46 -33 67 -33 25 -39 26 -174 26 l-141 0 -31 -29 -31 -29 0 29 0 29 -74 0 c-58 0 -75 -3 -80 -16 -3 -9 -6 -64 -6 -124 0 -167 -11 -192 -76 -176 l-24 6 0 155 0 155 -70 0 c-58 0 -71 -3 -80 -19 -5 -11 -10 -78 -10 -149 0 -142 -5 -156 -57 -150 l-28 3 -3 158 -3 157 -73 0 c-58 0 -75 -3 -80 -16z m898 -90 c10 -4 16 -18 16 -35 0 -25 -4 -29 -27 -29 -66 0 -96 25 -67 55 16 15 50 20 78 9z" />
      <path d="M1952 2148 c-9 -9 -12 -70 -12 -214 0 -226 -4 -214 76 -214 31 0 50 6 64 20 19 19 20 33 20 220 l0 200 -68 0 c-40 0 -73 -5 -80 -12z" />
      <path d="M2126 2144 c-3 -9 -6 -92 -6 -185 0 -168 0 -170 25 -193 52 -48 196 -71 287 -46 53 15 96 55 110 102 11 36 13 38 20 18 37 -101 166 -154 291 -119 47 13 106 63 123 106 20 47 18 106 -4 127 -12 10 -60 22 -133 33 -63 9 -118 20 -123 24 -4 4 4 15 18 24 34 22 118 23 168 2 36 -15 38 -15 53 6 21 30 19 73 -5 97 -18 18 -33 20 -148 20 -126 0 -130 -1 -169 -29 -22 -16 -50 -48 -61 -72 l-22 -44 0 73 0 72 -70 0 c-58 0 -71 -3 -80 -19 -5 -11 -10 -78 -10 -148 0 -139 -5 -153 -51 -153 -59 0 -59 -1 -59 166 l0 154 -74 0 c-58 0 -75 -3 -80 -16z m697 -273 c12 -12 -21 -41 -47 -41 -25 0 -76 35 -76 52 0 12 110 2 123 -11z" />
      <path d="M1961 1673 c-12 -10 -24 -35 -28 -55 -5 -32 -1 -41 25 -68 41 -40 83 -41 123 -1 37 38 39 82 4 116 -30 30 -92 34 -124 8z" />
      <path d="M465 1424 c-225 -49 -394 -205 -450 -414 -24 -90 -20 -115 23 -155 34 -30 58 -70 76 -127 3 -11 15 -18 29 -18 47 0 118 -33 161 -74 54 -53 81 -126 73 -200 l-6 -51 47 -30 c33 -21 54 -45 72 -82 l25 -52 75 0 c288 -2 510 169 550 423 20 127 -9 199 -95 237 -22 10 -164 35 -325 59 -157 23 -294 45 -305 49 -18 7 -15 12 25 54 60 63 128 87 248 87 96 0 177 -17 261 -56 49 -22 56 -23 76 -10 63 41 90 144 57 218 -26 59 -74 94 -169 125 -101 33 -337 42 -448 17z m89 -739 c214 -36 206 -34 199 -53 -14 -39 -49 -75 -87 -93 -119 -54 -259 15 -290 144 -8 34 -14 34 178 2z" />
      <path d="M1445 1426 c-144 -28 -244 -85 -300 -169 -45 -69 -58 -128 -53 -235 5 -101 33 -168 93 -220 80 -71 158 -94 416 -122 100 -11 184 -22 187 -25 10 -10 -10 -58 -34 -80 -66 -61 -274 -61 -466 0 -49 16 -59 16 -74 4 -29 -24 -54 -91 -54 -149 0 -98 46 -143 186 -182 110 -29 405 -32 504 -4 207 58 309 167 332 355 5 45 8 204 6 353 -3 235 -5 277 -20 304 -36 65 -128 122 -256 156 -100 26 -362 34 -467 14z m318 -305 c27 -10 27 -12 27 -99 l0 -89 -52 4 c-114 9 -194 24 -218 42 -56 41 -46 110 20 144 28 15 51 17 115 14 44 -3 92 -10 108 -16z" />
      <path d="M2524 1425 c-122 -26 -204 -88 -250 -187 l-29 -63 -3 -577 -3 -576 58 -9 c100 -14 215 -8 258 13 69 34 80 59 83 195 l4 119 154 0 154 0 16 38 c25 58 29 169 7 212 -29 57 -69 70 -211 70 l-123 0 3 196 3 196 33 29 c30 27 38 29 110 29 42 0 92 -4 109 -9 30 -9 34 -7 55 27 18 29 23 51 23 108 0 62 -3 75 -28 107 -16 21 -40 45 -54 54 -61 40 -248 54 -369 28z" />
    </g>
    <g id="layer102" fill="#ffffff" stroke="none">
      <path d="M36 2144 c-3 -9 -6 -92 -6 -185 0 -168 0 -170 25 -193 55 -51 196 -72 275 -40 36 15 44 15 87 0 102 -35 217 -7 256 62 11 19 22 61 25 98 l5 65 22 -21 c27 -25 66 -37 156 -46 73 -7 83 -18 38 -41 -32 -17 -65 -16 -124 2 -56 16 -70 10 -80 -36 -14 -64 47 -99 173 -99 121 0 200 45 217 121 l7 32 13 -29 c31 -73 121 -124 221 -124 93 0 134 28 134 89 0 48 -18 65 -54 51 -85 -32 -156 11 -156 95 0 78 62 120 142 96 35 -11 42 -10 59 5 19 17 19 12 19 -229 l0 -247 64 0 c79 0 96 17 96 95 l0 50 83 0 c97 0 138 19 168 77 16 32 19 60 19 203 l0 165 -70 0 c-58 0 -71 -3 -80 -19 -5 -11 -10 -76 -10 -145 0 -135 -7 -156 -55 -156 -55 0 -55 0 -55 166 l0 154 -69 0 c-49 0 -72 -4 -81 -15 -12 -14 -15 -14 -30 0 -13 11 -42 15 -127 15 -106 0 -111 -1 -151 -30 -23 -16 -50 -47 -61 -67 l-20 -38 -1 42 c0 36 -5 46 -33 67 -33 25 -39 26 -174 26 l-141 0 -31 -29 -31 -29 0 29 0 29 -74 0 c-58 0 -75 -3 -80 -16 -3 -9 -6 -64 -6 -124 0 -167 -11 -192 -76 -176 l-24 6 0 155 0 155 -70 0 c-58 0 -71 -3 -80 -19 -5 -11 -10 -78 -10 -149 0 -142 -5 -156 -57 -150 l-28 3 -3 158 -3 157 -73 0 c-58 0 -75 -3 -80 -16z m898 -90 c10 -4 16 -18 16 -35 0 -25 -4 -29 -27 -29 -66 0 -96 25 -67 55 16 15 50 20 78 9z" />
      <path d="M1952 2148 c-9 -9 -12 -70 -12 -214 0 -226 -4 -214 76 -214 31 0 50 6 64 20 19 19 20 33 20 220 l0 200 -68 0 c-40 0 -73 -5 -80 -12z" />
      <path d="M2126 2144 c-3 -9 -6 -92 -6 -185 0 -168 0 -170 25 -193 52 -48 196 -71 287 -46 53 15 96 55 110 102 11 36 13 38 20 18 37 -101 166 -154 291 -119 47 13 106 63 123 106 20 47 18 106 -4 127 -12 10 -60 22 -133 33 -63 9 -118 20 -123 24 -4 4 4 15 18 24 34 22 118 23 168 2 36 -15 38 -15 53 6 21 30 19 73 -5 97 -18 18 -33 20 -148 20 -126 0 -130 -1 -169 -29 -22 -16 -50 -48 -61 -72 l-22 -44 0 73 0 72 -70 0 c-58 0 -71 -3 -80 -19 -5 -11 -10 -78 -10 -148 0 -139 -5 -153 -51 -153 -59 0 -59 -1 -59 166 l0 154 -74 0 c-58 0 -75 -3 -80 -16z m697 -273 c12 -12 -21 -41 -47 -41 -25 0 -76 35 -76 52 0 12 110 2 123 -11z" />
      <path d="M1961 1673 c-12 -10 -24 -35 -28 -55 -5 -32 -1 -41 25 -68 41 -40 83 -41 123 -1 37 38 39 82 4 116 -30 30 -92 34 -124 8z" />
    </g>
  </svg>
);

Logo.propTypes = {
  className: PropTypes.string,
};

Logo.defaultProps = {
  className: null,
};

export default Logo;
