export default {
  ra: {
    action: {
      download: 'Télécharger',
    },
  },
  resources: {
    dashboard: {
      title: 'Tableau de bord',
      elements: {
        metrics: {
          title: 'Mesures depuis minuit',
        },
        orders: {
          title: 'Commandes du jour',
        },
        product_overviews: {
          title: 'Stock',
        },
        sale_overviews: {
          title: 'Ventes du jour',
        },
      },
    },
    users: {
      name: 'Utilisateur |||| Utilisateurs',
      fields: {
        email: 'Email',
        nfc: 'NFC',
        pos: 'Terminal de paiement',
        enabled: 'Activé ?',
        roles: 'Rôles',
        createdAt: 'Date de création',
      },
      tabs: {
        general: 'Informations',
        orders: 'Commandes',
      },
      roles: {
        ROLE_USER: 'Utilisateur',
        ROLE_CLIENT: 'Client',
        ROLE_DELIVERER: 'Livreur',
        ROLE_RESTAURATEUR: 'Restaurateur',
        ROLE_ADMIN: 'Administrateur',
      },
      unidentified: 'Utilisateur sans identifiant',
    },
    fridges: {
      name: 'Frigo |||| Frigos',
      fields: {
        name: 'Nom',
        slug: 'Slug',
        restaurateur: 'Restaurateur',
        uuid: 'Identifiant',
        enabled: 'Activé ?',
        status: 'Statut',
        deliveredAt: 'Dernière livraison',
        fridge_sales_sum: 'Revenu total (depuis dernière livraison)',
        fridge_clients_count: 'Clients (depuis dernière livraison)',
        temperature: 'Température (°C)',
        piElectroniqueVendingId: 'PIElectronique vending ID',
        posTerminalId: 'POS Terminal',
        posTerminalContact: 'POS Derniers contacts',
        medias: 'Medias',
        medias_desc: 'Déposez le média à uploader, ou cliquez pour le selectionner. Format recommendé: 690x300.',
        logo: 'Logo',
        customThemePrimaryColor: 'Couleur des boutons',
        customThemeHeaderBackgroundColor: 'Couleur du header',
        customThemeBodyBackgroundColor: 'Couleur de fond',
        themeEnableHomePaymentMethodsHelp: 'Afficher le guide de paiement',
        reductionStartHour: 'Heure de début de réduction',
        reductionEndHour: 'Heure de fin de réduction',
        enableNoWasteOffer: "Activer l'offre anti-gaspi",
        noWasteOfferDelay: "Délai de déclenchement de l'offre anti-gaspi avant la DLC (en heures)",
        hideQRCode: 'Masquer le QR code kiosk',
      },
      tabs: {
        general: 'Informations',
        product_overviews: 'Stock',
        sale_overviews: 'Ventes',
        outdated_product_overviews: 'DLC proches',
        temperature_readings: 'Températures',
        medias: 'Médias',
        offers: 'Offres',
      },
      values: {
        online: 'En ligne',
        offline: 'Hors ligne',
      },
    },
    fridge_media: {
      name: 'Media |||| Medias',
      fields: {
        position: 'Position',
        'media.src': 'Fichier',
      },
      upload: {
        error: 'Impossible d\'uploader ce média.',
      },
    },
    products: {
      name: 'Produit |||| Produits',
      fields: {
        name: 'Nom',
        description: 'Description',
        picture: 'Image',
        'picture.src': 'Image',
        tax: 'T.V.A.',
        price: 'Prix',
        timedReducedPrice: "Prix réduit selon l'horaire",
        noWastePrice: 'Prix anti-gaspi',
        nutriScore: 'Nutri-Score',
        restaurateur: 'Restaurateur',
        expirationDelay: "Délai d'expiration (jours)",
        piElectroniqueArticleRef: 'PIElectronique article ref',
        eligibleTitresRestaurant: 'Eligible aux tickets restaurants',
      },
    },
    offers: {
      name: 'Offre |||| Offres',
      fields: {
        product: 'Produit',
        price: 'Prix',
        rfid: 'Tag rfid',
        fridge: 'Frigo',
        tax: 'T.V.A.',
        expireAt: 'DLC',
      },
    },
    pending_offers: {
      name: 'Offre en attente |||| Offres en attente',
      fields: {
        product: 'Produit',
        rfid: 'Tag rfid',
      },
    },
    orders: {
      name: 'Commande |||| Commandes',
      fields: {
        client: 'Client',
        total: 'Total',
        status: 'Statut',
        date: 'Date',
        fridgeSlug: 'Slug frigo',
        fridgeName: 'Nom frigo',
        lines: 'Montant total',
        video: 'Vidéo',
      },
      download_video: 'Télécharger la vidéo',
      statuses: {
        initialized: 'Ouverture de la porte du frigo demandée',
        door_opened: 'Porte du frigo ouverte',
        rfid_scanned: 'Tags RFID scannés',
        door_timed_out: "Temps d'ouverture de la porte écoulé",
        payment_succeded: 'Payé',
        payment_waiting_capture: 'Paiement en attente de capture',
        payment_failed: 'Echec paiement',
        door_closed: 'Porte du frigo fermée',
      },
      tabs: {
        general: 'Informations',
        detail: 'Détails',
      },
      filters: {
        from: 'A partir de',
        to: 'Jusqu\'au',
      },
    },
    order_lines: {
      name: 'Détail de commande |||| Détails de commande',
      fields: {
        productName: 'Produit',
        price: 'Prix',
        quantity: 'Quantité',
        tax: 'T.V.A.',
      },
    },
    restaurateurs: {
      name: 'Restaurateur |||| Restaurateurs',
      fields: {
        name: 'Nom',
        description: 'Description',
        slug: 'Slug',
        commissionRate: 'Commission',
        billingInterval: 'Interval de facturation',
        'logo.src': 'Logo',
        color: 'couleur',
        createdAt: 'Date de création',
        streetLine1: 'Adresse 1',
        streetLine2: 'Adresse 2',
        postalCode: 'Code postal',
        city: 'Ville',
        region: 'Région',
        country: 'Pays',
        phone1: 'Téléphone 1',
        phone2: 'Téléphone 2',
        fax: 'Fax',
        email: 'Email',
        web: 'Site web',
        piElectroniqueScorePi: 'PiElectronique score PI',
        businessIdentifier: 'Siret',
        paygreenShopId: 'Paygreen Shop Id',
        adyenAccountId: 'Adyen Account Id',
        edenredMerchantId: 'Edenred Merchant Id',
        mcc: 'mcc',
        annualProcessingVolume: 'CA prévisionnel des 12 prochains mois',
        averageTransactionValue: 'Panier moyen',
        highestTransactionValue: 'Transaction la plus élevée attendue',
      },
      values: {
        daily: 'Journalier',
        bi_weekly: 'Bi-hebdomadaire',
        weekly: 'Hebdomadaire',
        monthly: 'Mensuel',
      },
      tabs: {
        general: 'Informations',
        managers: 'Managers',
        fridges: 'Frigos',
        deliverers: 'Livreurs',
      },
    },
    managers: {
      name: 'Manager |||| Managers',
      fields: {
        targetUser: 'Utilisateur',
        restaurateur: 'Restaurateur',
      },
    },
    metrics: {
      today_sales_sum: {
        title: 'Ventes totales',
      },
      today_client_count: {
        title: 'Clients',
      },
    },
    product_overviews: {
      name: 'Stock |||| Stocks',
      fields: {
        product: 'Produit',
        stockCount: 'Stock',
      },
    },
    sale_overviews: {
      name: 'Vente |||| Ventes',
      fields: {
        product: 'Produit',
        salesSum: 'Ventes',
        salesCount: 'Vendus',
      },
    },
    deliverers: {
      name: 'Livreur |||| Livreurs',
      fields: {
        targetUser: 'Livreur',
        restaurateur: 'Restaurateur',
      },
    },
    customers: {
      name: 'Client |||| Clients',
      fields: {
        user: 'Client',
        salesCount: 'Nombre de commandes',
        salesSum: 'Total',
      },
      tabs: {
        general: 'Informations',
        orders: 'Commandes',
      },
      actions: {
        reportAbuse: 'Déclarer un abus',
      },
    },
    daily_sales_reports: {
      name: 'Bilan des ventes |||| Bilans des ventes',
      fields: {
        date: 'Date',
        total: 'Total',
      },
    },
    invoices: {
      name: 'Facture |||| Factures',
      fields: {
        restaurateur: 'Restaurateur',
        paymentStatus: 'Statut',
        date: "Date d'émission",
        datePeriod: 'Période de facturation',
        dateStart: 'Date de début',
        dateEnd: 'Date de fin',
        number: 'Numéro',
        total: 'Montant',
        url: 'Télécharger',
      },
      period: {
        from: 'Du',
        to: 'au',
      },
      values: {
        paid: 'Versé',
        unpaid: 'Non versé',
      },
      actions: {
        apply_paid_status: 'Appliquer le statut versé',
        apply_paid_status_confirm:
          'Êtes-vous sûr de vouloir appliquer le statut versé sur ces éléments ?',
        generate_sepa_xml_file: 'Générer le fichier SEPA XML',
        generate_sepa_xml_file_confirm:
          'Êtes-vous sûr de vouloir générer le fichier SEPA XML sur ces éléments ?',
      },
    },
    temperature_readings: {
      name: 'Relevé de température |||| Relevés de température',
      fields: {
        temperature: 'Température (°C)',
        createdAt: 'Date',
      },
    },
  },
};
