import React from 'react';
import {
  Show, SimpleShowLayout, ReferenceField, TextField,
} from 'react-admin';
import { get } from 'lodash';
import * as PropTypes from 'prop-types';
import { ROLE_ADMIN } from '../providers/authProvider';

const DelivererShow = (props) => {
  const { permissions } = props;
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <ReferenceField source="targetUser" reference="users" link="show">
          <TextField source="email" />
        </ReferenceField>
        {get(permissions, 'roles', []).includes(ROLE_ADMIN) && (
          <ReferenceField source="restaurateur" reference="restaurateurs" link="show">
            <TextField source="name" />
          </ReferenceField>
        )}
      </SimpleShowLayout>
    </Show>
  );
};

DelivererShow.propTypes = {
  permissions: PropTypes.shape({
    roles: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }),
};

DelivererShow.defaultProps = {
  permissions: null,
};

export default DelivererShow;
