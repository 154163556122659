import React from 'react';
import {
  List, Datagrid, TextField, ReferenceField, NumberField, Filter, DateInput,
} from 'react-admin';
import * as PropTypes from 'prop-types';

const SaleOverviewListFilter = (props) => (
  <Filter {...props}>
    <DateInput source="since" alwaysOn />
  </Filter>
);

export const SaleOverviewDataGrid = ({ sortable, ...props }) => (
  <Datagrid {...props}>
    <ReferenceField source="product" reference="products" link="show" sortable={sortable} sortBy="product.name">
      <TextField source="name" />
    </ReferenceField>
    <NumberField source="salesSum" options={{ style: 'currency', currency: 'EUR' }} sortable={sortable} />
    <NumberField source="salesCount" sortable={sortable} />
  </Datagrid>
);

SaleOverviewDataGrid.propTypes = {
  sortable: PropTypes.bool,
};

SaleOverviewDataGrid.defaultProps = {
  sortable: true,
};

const SaleOverviewList = (props) => (
  <List bulkActionButtons={false} exporter={false} filters={<SaleOverviewListFilter />} {...props}>
    <SaleOverviewDataGrid />
  </List>
);

export const TodaySaleOverviewList = (props) => (
  <List
    pagination={null}
    filters={null}
    perPage={1000}
    filter={{ pagination: 'false', since: (new Date()).toISOString() }}
    bulkActionButtons={false}
    exporter={false}
    {...props}
  >
    <SaleOverviewDataGrid sortable={false} />
  </List>
);

export default SaleOverviewList;
