import React from 'react';
import {
  Create, SimpleForm, translate as withTranslate,
} from 'react-admin';
import * as PropTypes from 'prop-types';
import inputs from './inputs';

const FridgeMediaCreate = ({ translate, fridge, ...props }) => (
  <Create {...props}>
    <SimpleForm redirect="show" initialValues={{ fridge: fridge?.id }}>
      {inputs(translate, fridge)}
    </SimpleForm>
  </Create>
);

FridgeMediaCreate.propTypes = {
  translate: PropTypes.func.isRequired,
  fridge: PropTypes.shape({
    id: PropTypes.string,
  }),
};

FridgeMediaCreate.defaultProps = {
  fridge: null,
};

export default withTranslate(FridgeMediaCreate);
