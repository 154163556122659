import React from 'react';
import PropTypes from 'prop-types';
import { ImageField, useRecordContext } from 'react-admin';

const FridgeMediaField = (props) => {
  const record = useRecordContext(props);

  if (!record.media) return null;

  if (record.media.src.match(/.(m4v|avi|mpg|mp4)$/i)) {
    return (
      <video muted controls src={record.media.src} style={{ maxWidth: '300px' }} />
    );
  }

  if (record.media.src.match(/.(jpg|jpeg|png|gif|svg)$/i)) {
    return (
      <ImageField record={record} source="media.src" />
    );
  }

  return (
    <div>{record.media.src}</div>
  );
};

FridgeMediaField.propTypes = {
  record: PropTypes.shape({
    media: PropTypes.shape({
      src: PropTypes.string,
    }),
  }),
};

FridgeMediaField.defaultProps = {
  record: null,
};

export default FridgeMediaField;
