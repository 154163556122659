import React from 'react';
import { Login } from 'react-admin';
import { HydraAdmin } from '@api-platform/admin';
import { createTheme } from '@material-ui/core/styles';
import authProvider from '../providers/authProvider';
import dataProvider from '../providers/dataProvider';
import Dashboard from '../dashboard/Dashboard';
import i18nProvider from '../providers/i18nProvider';
import resources from './resources';
import { entrypoint } from '../config';
import Layout from './Layout';

const theme = createTheme({
  palette: {
    type: 'light', // Switching the dark mode on is a single property value change.
  },
});

const customTheme = {
  ...theme,
  palette: {
    ...theme.palette,
    primary: {
      main: '#2a2a72',
      light: '#5a53a1',
      dark: '#000146',
      contrastText: '#fff',
    },
    secondary: {
      main: '#2a2a72',
      light: '#5a53a1',
      dark: '#000146',
      contrastText: '#fff',
    },
  },
};

const LoginPage = () => <Login backgroundImage="/login-background.jpeg" />;

const Admin = () => (
  <HydraAdmin
    entrypoint={entrypoint}
    theme={customTheme}
    layout={Layout}
    i18nProvider={i18nProvider}
    authProvider={authProvider}
    dataProvider={dataProvider}
    dashboard={Dashboard}
    loginPage={LoginPage}
  >
    {resources}
  </HydraAdmin>
);

export default Admin;
