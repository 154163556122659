import React, { useMemo } from 'react';
import { get } from 'lodash';
import {
  TextField,
  BooleanField,
  NumberField,
  Tab,
  translate as withTranslate,
} from 'react-admin';
import * as PropTypes from 'prop-types';
import StatusField from '../StatusField';
import MetricField from '../MetricField';
import { ROLE_ADMIN } from '../../providers/authProvider';

const GeneralTab = (props) => {
  const { permissions, translate, ...rest } = props;
  const isAdmin = useMemo(
    () => get(permissions, 'roles', []).includes(ROLE_ADMIN),
    [permissions],
  );
  return (
    <Tab {...rest} label={translate('resources.fridges.tabs.general')}>
      {isAdmin && (
        <TextField source="restaurateur.name" label={translate('resources.restaurateurs.name', 1)} />
      )}
      <TextField source="name" />
      <TextField source="slug" />
      {isAdmin && <TextField source="uuid" />}
      <BooleanField source="enabled" />
      <StatusField target="available" source="status" />
      {isAdmin && <TextField source="posTerminalId" />}
      <MetricField source="fridge_sales_sum">
        <NumberField
          source="value"
          options={{ style: 'currency', currency: 'EUR' }}
        />
      </MetricField>
      <MetricField source="fridge_clients_count">
        <NumberField source="value" />
      </MetricField>
      <NumberField source="temperature" />
      {isAdmin && <TextField source="piElectroniqueScorePi" />}
      {isAdmin && <TextField source="piElectroniqueVendingId" />}
    </Tab>
  );
};

GeneralTab.propTypes = {
  permissions: PropTypes.shape({
    roles: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
};

export default withTranslate(GeneralTab);
