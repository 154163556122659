import React from 'react';
import * as PropTypes from 'prop-types';
import { RadioButtonGroupInput, translate as withTranslate } from 'react-admin';

const BillingIntervalInput = (props) => {
  const { translate, ...rest } = props;
  return (
    <RadioButtonGroupInput
      choices={[
        { id: 'daily', name: translate('resources.restaurateurs.values.daily') },
        { id: 'bi_weekly', name: translate('resources.restaurateurs.values.bi_weekly') },
        { id: 'weekly', name: translate('resources.restaurateurs.values.weekly') },
        { id: 'monthly', name: translate('resources.restaurateurs.values.monthly') },
      ]}
      {...rest}
    />
  );
};

BillingIntervalInput.propTypes = {
  translate: PropTypes.func.isRequired,
};

export default withTranslate(BillingIntervalInput);
