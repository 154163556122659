import React from 'react';
import {
  ReferenceManyField, Tab, Pagination, translate as withTranslate,
} from 'react-admin';
import * as PropTypes from 'prop-types';
import { SaleOverviewDataGrid } from '../../sale_overview/List';

const SaleOverviewsTab = (props) => {
  const { translate, record: { deliveredAt, createdAt }, ...rest } = props;
  const since = deliveredAt || createdAt;
  return (
    <Tab {...rest} label={translate('resources.fridges.tabs.sale_overviews')}>
      <ReferenceManyField
        reference="sale_overviews"
        target="fridge"
        source="originId"
        pagination={<Pagination />}
        addLabel={false}
        filter={since ? { since } : {}}
      >
        <SaleOverviewDataGrid />
      </ReferenceManyField>
    </Tab>
  );
};

SaleOverviewsTab.propTypes = {
  translate: PropTypes.func.isRequired,
  record: PropTypes.shape({
    deliveredAt: PropTypes.string,
    createdAt: PropTypes.string,
  }),
};

SaleOverviewsTab.defaultProps = {
  record: {
    createdAt: null,
    deliveredAt: null,
  },
};

export default withTranslate(SaleOverviewsTab);
