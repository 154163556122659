import React from 'react';
import {
  List,
  Datagrid,
  ReferenceField,
  TextField,
  ShowButton,
} from 'react-admin';
import * as PropTypes from 'prop-types';
import { get } from 'lodash';
import fields from './fields';
import { ROLE_ADMIN } from '../providers/authProvider';

const DelivererList = (props) => {
  const { permissions } = props;
  return (
    <List exporter={false} {...props}>
      <Datagrid rowClick="show">
        {fields}
        {get(permissions, 'roles', []).includes(ROLE_ADMIN) && (
          <ReferenceField source="restaurateur" reference="restaurateurs" link="show" sortBy="restaurateur.name">
            <TextField source="name" />
          </ReferenceField>
        )}
        <ShowButton />
      </Datagrid>
    </List>
  );
};

DelivererList.propTypes = {
  permissions: PropTypes.shape({
    roles: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }),
};

DelivererList.defaultProps = {
  permissions: null,
};

export default DelivererList;
