import React from 'react';
import formatDistance from 'date-fns/formatDistance';
import parseJSON from 'date-fns/parseJSON';
import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  FunctionField,
  withTranslate,
} from 'react-admin';
import { get } from 'lodash';
import * as PropTypes from 'prop-types';
import fields from './fields';
import { ROLE_ADMIN } from '../providers/authProvider';

const POSTerminalField = (props) => (
  <FunctionField
    {...props}
    render={(record) => {
      const {
        posTerminalLastScanModeAt: scanMode,
        posTerminalLastScanModeRequestAt: request,
      } = record;
      const now = new Date();
      const lastScan = scanMode && parseJSON(scanMode);
      const lastScanRequest = request && parseJSON(request);
      return (
        <>
          Scan:
          <span title={lastScan}>
            {lastScan
              ? formatDistance(lastScan, now, { addSuffix: true })
              : ' - '}
          </span>
          <br />
          Request:
          <span title={request}>
            {request
              ? formatDistance(lastScanRequest, now, { addSuffix: true })
              : ' - '}
          </span>
        </>
      );
    }}
  />
);
const FridgeList = (props) => {
  const { permissions, translate, ...rest } = props;
  const isAdmin = get(permissions, 'roles', []).includes(ROLE_ADMIN);
  return (
    <List exporter={false} {...rest}>
      <Datagrid rowClick="show">
        {isAdmin && (
          <TextField
            key="restaurateur.name"
            source="restaurateur.name"
            label={translate('resources.restaurateurs.name', 1)}
          />
        )}
        {isAdmin && <TextField key="posTerminalId" source="posTerminalId" />}
        {isAdmin && (
          <POSTerminalField
            label={translate('resources.fridges.fields.posTerminalContact')}
            target="posTerminalContact"
          />
        )}
        {fields}
        <ShowButton />
      </Datagrid>
    </List>
  );
};

FridgeList.propTypes = {
  permissions: PropTypes.shape({
    roles: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }),
  translate: PropTypes.func.isRequired,
};

FridgeList.defaultProps = {
  permissions: null,
};

export default withTranslate(FridgeList);
