import React from 'react';
import * as PropTypes from 'prop-types';
import { FunctionField, translate as withTranslate } from 'react-admin';

const PaymentStatusField = (props) => {
  const { translate, ...rest } = props;
  return (
    <FunctionField
      render={(record, source) => translate(`resources.invoices.values.${record[source]}`)}
      {...rest}
    />
  );
};

PaymentStatusField.propTypes = {
  translate: PropTypes.func.isRequired,
  addLabel: PropTypes.bool,
};

PaymentStatusField.defaultProps = {
  addLabel: true,
};

export default withTranslate(PaymentStatusField);
