import { get } from 'lodash';
import { entrypoint } from '../config';

const fetchApi = (url, options) => {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/ld+json',
  };

  if (localStorage.getItem('token')) {
    headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }

  return fetch(url, {
    headers,
    ...options,
  });
};

export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_RESTAURATEUR = 'ROLE_RESTAURATEUR';
export const roles = [ROLE_ADMIN, ROLE_RESTAURATEUR];

const userCheck = (me) => (roles.filter((role) => get(me, 'roles', []).includes(role)).length > 0
  ? Promise.resolve(me)
  : Promise.reject());

const setUser = (user) => localStorage.setItem('user', JSON.stringify(user));
const getUser = () => JSON.parse(localStorage.getItem('user'));

const authProvider = {
  login: ({ username, password }) => fetchApi(
    `${entrypoint}/login`, { method: 'POST', body: JSON.stringify({ username, password }) },
  )
    .then((response) => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error('ra.auth.sign_in_error');
      }
      return response.json();
    })
    .then((response) => {
      const { token } = response;
      localStorage.setItem('token', token);
      return token;
    })
    .then(() => fetchApi(`${entrypoint}/me`).then((response) => response.json()))
    .then(userCheck)
    .then((me) => setUser(me)),
  logout: () => {
    localStorage.removeItem('token');
    return fetchApi(`${entrypoint}/logout`, { method: 'POST' })
      .then(() => setUser(null));
  },
  checkError: (error) => {
    const { status } = error;
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      return Promise.reject();
    }

    const { response } = error;
    if (response && response.status && (response.status === 401 || response.status === 403)) {
      localStorage.removeItem('token');
      return Promise.reject();
    }

    return Promise.resolve();
  },
  checkAuth: () => {
    const user = getUser();
    return user ? Promise.resolve() : fetchApi(`${entrypoint}/me`)
      .then((response) => (response.status < 200 || response.status >= 300
        ? Promise.reject()
        : response.json()))
      .then(userCheck)
      .then((me) => setUser(me));
  },
  getIdentity: () => {
    const user = getUser();
    return user ? Promise.resolve(user) : Promise.reject();
  },
  getPermissions: () => {
    const user = getUser();
    return user ? Promise.resolve(user) : Promise.reject();
  },
};

export default authProvider;
