import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';
import * as PropTypes from 'prop-types';
import { get } from 'lodash';
import inputs from './inputs';
import { ROLE_ADMIN } from '../providers/authProvider';

const ProductCreate = (props) => {
  const { permissions, ...rest } = props;

  return (
    <Create {...rest}>
      <SimpleForm redirect="show">
        {inputs}
        {
          get(permissions, 'roles', []).includes(ROLE_ADMIN)
          && (
            <TextInput
              key="piElectroniqueArticleRef"
              source="piElectroniqueArticleRef"
              parse={(value) => value || null}
            />
          )
        }
      </SimpleForm>
    </Create>
  );
};

ProductCreate.propTypes = {
  permissions: PropTypes.shape({
    roles: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }),
};

ProductCreate.defaultProps = {
  permissions: null,
};

export default ProductCreate;
