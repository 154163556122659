import React from 'react';
import {
  List, Datagrid, TextField, ReferenceField, NumberField,
} from 'react-admin';
import * as PropTypes from 'prop-types';

export const ProductOverviewDatagrid = ({ sortable, ...props }) => (
  <Datagrid {...props}>
    <ReferenceField source="product" reference="products" link="show" sortBy="product.name" sortable={sortable}>
      <TextField source="name" />
    </ReferenceField>
    <NumberField source="stockCount" sortable={sortable} />
  </Datagrid>
);

ProductOverviewDatagrid.propTypes = {
  sortable: PropTypes.bool,
};

ProductOverviewDatagrid.defaultProps = {
  sortable: true,
};

const ProductOverviewList = (props) => (
  <List bulkActionButtons={false} exporter={false} {...props}>
    <ProductOverviewDatagrid />
  </List>
);

export const TodayProductOverviewList = (props) => (
  <List
    pagination={null}
    perPage={1000}
    filter={{ pagination: 'false' }}
    bulkActionButtons={false}
    exporter={false}
    {...props}
  >
    <ProductOverviewDatagrid sortable={false} />
  </List>
);

export default ProductOverviewList;
