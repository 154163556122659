import React, { useMemo } from 'react';
import {
  Tab, ReferenceManyField, Pagination, translate as withTranslate,
} from 'react-admin';
import * as PropTypes from 'prop-types';
import { ProductOverviewDatagrid } from '../../product_overview/List';

const OutdatedProductOverviewsTab = (props) => {
  const { translate, ...rest } = props;
  const expireBefore = useMemo(() => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow;
  }, []);
  return (
    <Tab {...rest} label={translate('resources.fridges.tabs.outdated_product_overviews')}>
      <ReferenceManyField
        reference="product_overviews"
        target="fridge"
        source="originId"
        pagination={<Pagination />}
        addLabel={false}
        filter={{ expire_before: expireBefore.toISOString() }}
      >
        <ProductOverviewDatagrid />
      </ReferenceManyField>
    </Tab>
  );
};

OutdatedProductOverviewsTab.propTypes = {
  translate: PropTypes.func.isRequired,
};

export default withTranslate(OutdatedProductOverviewsTab);
