import React from 'react';
import {
  Edit, NumberInput, SimpleForm, translate as withTranslate,
} from 'react-admin';
import * as PropTypes from 'prop-types';
import FridgeMediaField from './FridgeMediaField';

const FridgeMediaEdit = ({ translate, fridge, ...props }) => {
  const redirect = (basePath, id, data) => (
    data.fridge ? `/fridges/${encodeURIComponent(data.fridge)}/show/medias` : 'list'
  );
  return (
    <Edit {...props}>
      <SimpleForm redirect={redirect}>
        <FridgeMediaField />
        <NumberInput key="position" source="position" />
      </SimpleForm>
    </Edit>
  );
};

FridgeMediaEdit.propTypes = {
  translate: PropTypes.func.isRequired,
  fridge: PropTypes.shape({
    id: PropTypes.string,
  }),
};

FridgeMediaEdit.defaultProps = {
  fridge: null,
};

export default withTranslate(FridgeMediaEdit);
