import React from 'react';
import {
  Tab,
  ReferenceManyField,
  translate as withTranslate,
  Pagination,
} from 'react-admin';
import * as PropTypes from 'prop-types';
import { TemperatureReadingDataGrid } from '../../temperature_reading/List';

const TemperaturesTab = (props) => {
  const { translate, ...rest } = props;
  return (
    <Tab {...rest} label={translate('resources.fridges.tabs.temperature_readings')}>
      <ReferenceManyField
        reference="temperature_readings"
        target="fridge"
        source="originId"
        pagination={<Pagination />}
        addLabel={false}
        sort={{ field: 'createdAt', order: 'desc' }}
      >
        <TemperatureReadingDataGrid />
      </ReferenceManyField>
    </Tab>
  );
};

TemperaturesTab.propTypes = {
  translate: PropTypes.func.isRequired,
};

export default withTranslate(TemperaturesTab);
