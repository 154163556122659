import React from 'react';
import { UserMenu as ReactAdminUserMenu, WithPermissions } from 'react-admin';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import Typography from '@material-ui/core/Typography';
import * as PropTypes from 'prop-types';
import { get } from 'lodash';
import styles from './usermenu.module.css';
import { ROLE_ADMIN, ROLE_RESTAURATEUR } from '../providers/authProvider';

const WithPermissionsUserMenu = ({ permissions, ...props }) => (
  <ReactAdminUserMenu {...props}>
    {get(permissions, 'roles', []).includes(ROLE_RESTAURATEUR) && get(permissions, 'manager.restaurateur.name') && (
      <li className={styles.title}>
        <Typography variant="h6">{permissions.manager.restaurateur.name}</Typography>
      </li>
    )}
    {get(permissions, 'roles', []).includes(ROLE_ADMIN) && (
      <li className={styles.title}>
        <Typography variant="h6">Admin</Typography>
      </li>
    )}
  </ReactAdminUserMenu>
);

WithPermissionsUserMenu.propTypes = {
  permissions: PropTypes.shape({
    manager: PropTypes.shape({
      restaurateur: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
    }),
  }),
};

WithPermissionsUserMenu.defaultProps = {
  permissions: null,
};

const UserMenu = (props) => (
  <WithPermissions render={({ permissions }) => <WithPermissionsUserMenu permissions={permissions} {...props} />} />
);

export default UserMenu;
