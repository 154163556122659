import React from 'react';
import {
  TextField, NumberField, ImageField, RichTextField,
} from 'react-admin';
import { get } from 'lodash';
import PercentField from '../common/PercentField';
import { NutriScoreField } from './NutriScore';
import { ROLE_ADMIN } from '../providers/authProvider';

export default (permissions) => [
  <TextField key="name" source="name" />,
  <RichTextField key="description" source="description" />,
  <ImageField key="picture.src" source="picture.src" />,
  <PercentField key="tax" source="tax" />,
  <NumberField key="price" source="price" options={{ style: 'currency', currency: 'EUR' }} />,
  <NumberField key="timedReducedPrice" source="timedReducedPrice" options={{ style: 'currency', currency: 'EUR' }} />,
  <NumberField key="noWastePrice" source="noWastePrice" options={{ style: 'currency', currency: 'EUR' }} />,
  <NumberField key="expirationDelay" source="expirationDelay" />,
  <NutriScoreField key="nutriScore" source="nutriScore" />,
  get(permissions, 'roles', []).includes(ROLE_ADMIN)
    ? <TextField key="piElectroniqueArticleRef" source="piElectroniqueArticleRef" />
    : null,
];
