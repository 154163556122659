import React from 'react';
import { NumberInput } from 'react-admin';

const isNumeric = (n) => !Number.isNaN(parseFloat(n)) && Number.isFinite(parseFloat(n));

const PercentInput = (props) => (
  <NumberInput
    format={(v) => (!isNumeric(v) ? '' : parseFloat((v * 100).toFixed(1)))}
    parse={(v) => (!isNumeric(v) ? null : parseFloat((v / 100).toFixed(3)))}
    {...props}
  />
);

export default PercentInput;
