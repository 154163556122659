import React from 'react';
import {
  Tab, ReferenceManyField, translate as withTranslate, Pagination,
} from 'react-admin';
import * as PropTypes from 'prop-types';
import { ProductOverviewDatagrid } from '../../product_overview/List';

const ProductOverviewsTab = (props) => {
  const { translate, ...rest } = props;
  return (
    <Tab {...rest} label={translate('resources.fridges.tabs.product_overviews')}>
      <ReferenceManyField
        reference="product_overviews"
        target="fridge"
        source="originId"
        pagination={<Pagination />}
        addLabel={false}
      >
        <ProductOverviewDatagrid />
      </ReferenceManyField>
    </Tab>
  );
};

ProductOverviewsTab.propTypes = {
  translate: PropTypes.func.isRequired,
};

export default withTranslate(ProductOverviewsTab);
