import React from 'react';
import { translate as withTranslate, useRecordContext, Labeled } from 'react-admin';
import PropTypes from 'prop-types';

const VideoField = (props) => {
  const { translate } = props;
  const record = useRecordContext(props);
  if (!record || !record.video) return null;
  return (
    <Labeled label={translate('resources.orders.fields.video')}>
      <a href={record.video} rel="noreferrer" target="_blank">{ translate('resources.orders.download_video') }</a>
    </Labeled>
  );
};

VideoField.propTypes = {
  translate: PropTypes.func.isRequired,
};

export default withTranslate(VideoField);
