import React from 'react';
import * as PropTypes from 'prop-types';
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  required, ImageField, ImageInput,
  translate as withTranslate,
  FormDataConsumer, NumberInput,
} from 'react-admin';
import { get } from 'lodash';
import { useField } from 'react-final-form';
import { BlockPicker } from 'react-color';
import { ROLE_ADMIN } from '../providers/authProvider';

const ColorInput = ({ source }) => {
  const {
    input: { onChange, value },
  } = useField(source);

  return (
    <BlockPicker
      color={value}
      onChangeComplete={(color) => onChange(color.hex)}
    />
  );
};

ColorInput.propTypes = {
  source: PropTypes.string.isRequired,
};

const FridgeEdit = (props) => {
  const { translate, permissions, ...rest } = props;
  return (
    <Edit {...rest}>
      <SimpleForm redirect="show">
        <BooleanInput source="enabled" validate={required()} />
        {get(permissions, 'roles', []).includes(ROLE_ADMIN) && (
          <>
            <ReferenceInput
              source="restaurateur"
              reference="restaurateurs"
              filterToQuery={(searchText) => ({ name: searchText })}
              validate={required()}
              format={(v) => v['@id'] || v}
            >
              <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <TextInput source="posTerminalId" />
            <BooleanInput source="scanOnTap" validate={required()} />
          </>
        )}
        <TextInput source="name" validate={required()} />
        {get(permissions, 'roles', []).includes(ROLE_ADMIN) && (
          <TextInput source="slug" validate={required()} />
        )}
        <ImageInput
          source="logo"
          label="resources.fridges.fields.logo"
          accept="image/*"
          maxSize={8000000}
        >
          <ImageField source="src" title="title" />
        </ImageInput>

        <BooleanInput source="customThemePrimaryColor" validate={required()} />
        <FormDataConsumer>
          {({ formData }) => formData.customThemePrimaryColor && (
            <ColorInput
              source="themePrimaryColor"
              label={translate('resources.fridges.fields.customThemePrimaryColor')}
            />
          )}
        </FormDataConsumer>

        <BooleanInput source="customThemeHeaderBackgroundColor" validate={required()} />
        <FormDataConsumer>
          {({ formData }) => formData.customThemeHeaderBackgroundColor && (
            <ColorInput
              source="themeHeaderBackgroundColor"
              label={translate('resources.fridges.fields.customThemeHeaderBackgroundColor')}
            />
          )}
        </FormDataConsumer>

        <BooleanInput source="customThemeBodyBackgroundColor" validate={required()} />
        <FormDataConsumer>
          {({ formData }) => formData.customThemeBodyBackgroundColor && (
            <ColorInput
              source="themeBodyBackgroundColor"
              label={translate('resources.fridges.fields.customThemeBodyBackgroundColor')}
            />
          )}
        </FormDataConsumer>

        <BooleanInput source="themeEnableHomePaymentMethodsHelp" validate={required()} />

        <BooleanInput source="hideQRCode" validate={required()} />

        {get(permissions, 'roles', []).includes(ROLE_ADMIN) && (
          <TextInput
            source="piElectroniqueScorePi"
            parse={(value) => value || null}
          />
        )}

        {get(permissions, 'roles', []).includes(ROLE_ADMIN) && (
          <TextInput
            source="piElectroniqueVendingId"
            parse={(value) => value || null}
          />
        )}

        <TextInput
          source="reductionStart"
          label={translate('resources.fridges.fields.reductionStartHour', 1)}
          type="time"
        />
        <TextInput
          source="reductionEnd"
          label={translate('resources.fridges.fields.reductionEndHour', 1)}
          type="time"
        />
        <NumberInput
          source="hoursBeforeNoWasteReduction"
          label={translate('resources.fridges.fields.noWasteOfferDelay', 1)}
        />
      </SimpleForm>
    </Edit>
  );
};

FridgeEdit.propTypes = {
  permissions: PropTypes.shape({
    roles: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }),
  translate: PropTypes.func.isRequired,
};

FridgeEdit.defaultProps = {
  permissions: null,
};

export default withTranslate(FridgeEdit);
