import React from 'react';
import * as PropTypes from 'prop-types';
import { RadioButtonGroupInput, translate as withTranslate } from 'react-admin';

const PaymentStatusInput = (props) => {
  const { translate, ...rest } = props;
  return (
    <RadioButtonGroupInput
      choices={[
        { id: 'unpaid', name: translate('resources.invoices.values.unpaid') },
        { id: 'paid', name: translate('resources.invoices.values.paid') },
      ]}
      {...rest}
    />
  );
};

PaymentStatusInput.propTypes = {
  translate: PropTypes.func.isRequired,
};

export default withTranslate(PaymentStatusInput);
