import React from 'react';
import { Show, SimpleShowLayout } from 'react-admin';
import MetricField from './Field';

const MetricShow = (unit) => (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <MetricField unit={unit} />
    </SimpleShowLayout>
  </Show>
);

export default MetricShow;
