import React from 'react';
import { TextField, BooleanField, NumberField } from 'react-admin';
import StatusField from './StatusField';

export default [
  <TextField key="name" source="name" />,
  <TextField key="slug" source="slug" />,
  <BooleanField key="enabled" source="enabled" />,
  <StatusField
    key="status"
    target="available"
    source="status"
    sortable={false}
  />,
  <NumberField key="temperature" source="temperature" />,
];
