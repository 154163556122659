import React from 'react';
import {
  List, Datagrid, ShowButton, NumberField, TextInput, Filter, ReferenceField, TextField,
} from 'react-admin';

const CustomerEmailFilter = (props) => (
  <Filter {...props}>
    <TextInput source="userEmail" alwaysOn />
  </Filter>
);

const CustomerList = (props) => (
  <List bulkActionButtons={false} exporter={false} filters={<CustomerEmailFilter />} {...props}>
    <Datagrid rowClick="show">
      <ReferenceField source="user" reference="users" link="show" sortBy="user.email">
        <TextField source="email" />
      </ReferenceField>
      <NumberField source="salesCount" />
      <NumberField source="salesSum" options={{ style: 'currency', currency: 'EUR' }} />
      <ShowButton />
    </Datagrid>
  </List>
);

export default CustomerList;
