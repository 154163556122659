import React, { useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { connect } from 'react-redux';
import {
  Button, Confirm, crudUpdate as reactAdminCrudUpdate, translate as withTranslate,
} from 'react-admin';
import * as PropTypes from 'prop-types';

const PaidActionButton = (props) => {
  const {
    basePath, crudUpdate, resource, selectedIds, translate,
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button label={translate('resources.invoices.actions.apply_paid_status')} onClick={() => setIsOpen(true)} />
      <Confirm
        isOpen={isOpen}
        title={translate('resources.invoices.actions.apply_paid_status')}
        content={translate('resources.invoices.actions.apply_paid_status_confirm')}
        onConfirm={() => {
          selectedIds.forEach((selectedId) => (
            crudUpdate(resource, selectedId, { paymentStatus: 'paid' }, {}, basePath, false)
          ));
          setIsOpen(false);
        }}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
};

PaidActionButton.propTypes = {
  basePath: PropTypes.string,
  crudUpdate: PropTypes.func.isRequired,
  resource: PropTypes.string.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  translate: PropTypes.func.isRequired,
};

PaidActionButton.defaultProps = {
  basePath: '',
};

export default connect(undefined, { crudUpdate: reactAdminCrudUpdate })(withTranslate(PaidActionButton));
