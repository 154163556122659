import { AutocompleteInput, ReferenceInput, required } from 'react-admin';
import React from 'react';
import { userOptionText } from '../user/fields';

export default (translate) => [
  <ReferenceInput
    key="targetUser"
    source="targetUser"
    reference="users"
    filterToQuery={(searchText) => ({ email: searchText })}
    validate={required()}
  >
    <AutocompleteInput optionText={(user) => user && userOptionText(user, translate)} />
  </ReferenceInput>,
  <ReferenceInput
    key="restaurateur"
    source="restaurateur"
    reference="restaurateurs"
    filterToQuery={(searchText) => ({ name: searchText })}
    validate={required()}
  >
    <AutocompleteInput optionText="name" />
  </ReferenceInput>,
];
