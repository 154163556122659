import React from 'react';
import {
  Show,
  TabbedShowLayout,
  TabbedShowLayoutTabs,
  translate as withTranslate,
} from 'react-admin';
import * as PropTypes from 'prop-types';
import { useMediaQuery } from '@material-ui/core';
import GeneralTab from './tabs/General';
import ProductOverviewsTab from './tabs/ProductOverviews';
import SaleOverviewsTab from './tabs/SaleOverviews';
import OutdatedProductOverviews from './tabs/OutdatedProductOverviews';
import TemperaturesTab from './tabs/Temperature';
import MediasTab from './tabs/Medias';
import OffersTab from './tabs/Offers';

const Title = ({ record: { name }, translate }) => (
  <span>{`${translate('resources.fridges.name', { smart_count: 1 })} ${name}`}</span>
);

Title.propTypes = {
  record: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  translate: PropTypes.func.isRequired,
};

Title.defaultProps = {
  record: {
    name: '',
  },
};

const TranslatedTitle = withTranslate(Title);

const FridgeShow = (props) => {
  const { permissions } = props;

  const scroll = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const style = scroll ? { maxWidth: '380px' } : {};

  return (
    <Show title={<TranslatedTitle />} {...props}>
      <TabbedShowLayout
        style={style}
        tabs={<TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto" {...props} />}
      >
        <SaleOverviewsTab />
        <ProductOverviewsTab path="products" />
        <OutdatedProductOverviews path="dlc" />
        <TemperaturesTab path="temperatures" />
        <GeneralTab permissions={permissions} path="infos" />
        <MediasTab path="medias" />
        <OffersTab path="offers" />
      </TabbedShowLayout>
    </Show>
  );
};

FridgeShow.propTypes = {
  permissions: PropTypes.shape({
    roles: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }),
};

FridgeShow.defaultProps = {
  permissions: null,
};

export default FridgeShow;
