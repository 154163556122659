import { createElement } from 'react';
import * as PropTypes from 'prop-types';

const DashboardShow = ({
  resource, id, show, match, location,
}) => createElement(show, {
  resource,
  id,
  match,
  location,
  basePath: `/${resource}`,
  title: ' ',
});

DashboardShow.propTypes = {
  id: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  show: PropTypes.func.isRequired,
  match: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
};

export default DashboardShow;
