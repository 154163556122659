export default {
  ra: {
    action: {
      download: 'Download',
    },
  },
  resources: {
    dashboard: {
      title: 'Dashboard',
      elements: {
        metrics: {
          title: 'Data since midnight',
        },
        orders: {
          title: 'Orders of the day',
        },
        product_overviews: {
          title: 'Stock',
        },
        sale_overviews: {
          title: 'Sales of the day',
        },
      },
    },
    users: {
      name: 'User |||| Users',
      fields: {
        email: 'Email',
        nfc: 'NFC',
        pos: 'Payment terminal',
        enabled: 'Enabled?',
        roles: 'Roles',
        createdAt: 'Creation date',
      },
      tabs: {
        general: 'Informations',
        orders: 'Orders',
      },
      roles: {
        ROLE_USER: 'User',
        ROLE_CLIENT: 'Client',
        ROLE_DELIVERER: 'Deliverer',
        ROLE_RESTAURATEUR: 'Restaurateur',
        ROLE_ADMIN: 'Administrator',
      },
      unidentified: 'Unidentified user',
    },
    fridges: {
      name: 'Fridge |||| Fridges',
      fields: {
        name: 'Name',
        slug: 'Slug',
        uuid: 'Identifier',
        enabled: 'Enabled?',
        status: 'Status',
        deliveredAt: 'Last delivery',
        fridge_sales_sum: 'Total income (since last delivery)',
        fridge_clients_count: 'Number of customers (since last delivery)',
        temperature: 'Temperature (°C)',
        piElectroniqueVendingId: 'PIElectronique vending ID',
        posTerminalId: 'POS Terminal',
        posTerminalContact: 'POS Last Contacts',
        medias: 'Medias',
        medias_desc: 'Dropin  the media to upload, or click to select it. Recommended format: 690x300.',
        logo: 'Logo',
        customThemePrimaryColor: 'Buttons color',
        customThemeHeaderBackgroundColor: 'Header color',
        customThemeBodyBackgroundColor: 'Body color',
        themeEnableHomePaymentMethodsHelp: 'Display the payment helpers',
        reductionStartHour: 'Promotion start hour',
        reductionEndHour: 'Promotion end hour',
        enableNoWasteOffer: "Activate 'No Waste' offer",
        noWasteOfferDelay: "Trigger delay of the 'No Waste' (in hours)",
        hideQRCode: 'Hide the kiosk QR code',
      },
      tabs: {
        general: 'Informations',
        product_overviews: 'Stock',
        sale_overviews: 'Sales',
        outdated_product_overviews: 'Close shelf lives',
        temperature_readings: 'Temperatures',
        medias: 'Medias',
        offers: 'Promotions',
      },
      values: {
        online: 'Online',
        offline: 'Offline',
      },
    },
    fridge_media: {
      name: 'Media |||| Medias',
      fields: {
        position: 'Position',
        'media.src': 'File',
      },
      upload: {
        error: 'Could not upload this media.',
      },
    },
    products: {
      name: 'Product |||| Products',
      fields: {
        name: 'Name',
        description: 'Description',
        picture: 'Picture',
        'picture.src': 'Picture',
        tax: 'V.A.T.',
        price: 'Price',
        nutriScore: 'Nutri-Score',
        restaurateur: 'Restaurateur',
        expirationDelay: 'Expiration date (in days)',
        piElectroniqueArticleRef: 'PIElectronique article ref',
        eligibleTitresRestaurant: 'Eligible to "tickets restaurants"',
      },
    },
    offers: {
      name: 'Offer |||| Offers',
      fields: {
        product: 'Product',
        price: 'Price',
        rfid: 'Rfid tag',
        fridge: 'Fridge',
        tax: 'V.A.T.',
        expireAt: 'Expiration date',
      },
    },
    pending_offers: {
      name: 'Pending offer |||| Pending offers',
      fields: {
        product: 'Product',
        rfid: 'Rfid tag',
      },
    },
    orders: {
      name: 'Order |||| Orders',
      fields: {
        client: 'Customer',
        total: 'Total',
        status: 'Status',
        date: 'Date',
        fridgeSlug: 'Fridge slug',
        fridgeName: 'Fridge’s name',
        lines: 'Total amount',
        video: 'Video',
      },
      download_video: 'Download video',
      statuses: {
        initialized: 'Opening of the fridge door requested',
        door_opened: 'Fridge door opened',
        rfid_scanned: 'RFID tags scanned',
        door_timed_out: 'Door opening time elapsed',
        payment_succeded: 'Paid',
        payment_waiting_capture: 'Payment waiting for capture',
        payment_failed: 'Payment failure',
        door_closed: 'Fridge door closed',
      },
      tabs: {
        general: 'Informations',
        detail: 'Details',
      },
      filters: {
        from: 'From',
        to: 'To',
      },
    },
    order_lines: {
      name: 'Order detail |||| Order details',
      fields: {
        productName: 'Product',
        price: 'Price',
        quantity: 'Quantity',
        tax: 'V.A.T.',
      },
    },
    restaurateurs: {
      name: 'Restaurateur |||| Restaurateurs',
      fields: {
        name: 'Name',
        description: 'Description',
        slug: 'Slug',
        commissionRate: 'Commission',
        billingInterval: 'Billing interval',
        'logo.src': 'Logo',
        color: 'Color',
        createdAt: 'Creation date',
        streetLine1: 'Street line 1',
        streetLine2: 'Street line 2',
        postalCode: 'Zip code',
        city: 'City',
        region: 'Region',
        country: 'Country',
        phone1: 'Phone 1',
        phone2: 'Phone 2',
        fax: 'Fax',
        email: 'Email',
        web: 'Website',
        piElectroniqueScorePi: 'PiElectronique score PI',
        businessIdentifier: 'Business identifier',
        paygreenShopId: 'Paygreen Shop Id',
        adyenAccountId: 'Adyen Account Id',
        edenredMerchantId: 'Edenred Merchant Id',
      },
      values: {
        daily: 'Daily',
        bi_weekly: 'Bi-weekly',
        weekly: 'Weekly',
        monthly: 'Monthly',
      },
      tabs: {
        general: 'Informations',
        managers: 'Managers',
        fridges: 'Fridges',
        deliverers: 'Deliverers',
      },
    },
    managers: {
      name: 'Manager |||| Managers',
      fields: {
        targetUser: 'User',
        restaurateur: 'Restaurateur',
      },
    },
    metrics: {
      today_sales_sum: {
        title: 'Total sales',
      },
      today_client_count: {
        title: 'Clients',
      },
    },
    product_overviews: {
      name: 'Stock |||| Stocks',
      fields: {
        product: 'Product',
        stockCount: 'Stock',
      },
    },
    sale_overviews: {
      name: 'Sale |||| Sales',
      fields: {
        product: 'Product',
        salesSum: 'Sales',
        salesCount: 'Sold',
      },
    },
    deliverers: {
      name: 'Deliverer |||| Deliverers',
      fields: {
        targetUser: 'Deliverer',
        restaurateur: 'Restaurateur',
      },
    },
    customers: {
      name: 'Customer |||| Customers',
      fields: {
        user: 'Customer',
        salesCount: 'Number of orders',
        salesSum: 'Total amount',
      },
      tabs: {
        general: 'Informations',
        orders: 'Orders',
      },
      actions: {
        reportAbuse: 'Report abuse',
      },
    },
    daily_sales_reports: {
      name: 'Sales figure |||| Sales figures',
      fields: {
        date: 'Date',
        total: 'Total amount',
      },
    },
    invoices: {
      name: 'Invoice |||| Invoices',
      fields: {
        restaurateur: 'Restaurateur',
        paymentStatus: 'Status',
        date: 'Date of issue',
        datePeriod: 'Billing period',
        dateStart: 'Start date',
        dateEnd: 'End date',
        number: 'Number',
        total: 'Amount',
        url: 'Download',
      },
      period: {
        from: 'From',
        to: 'To',
      },
      values: {
        paid: 'Paid',
        unpaid: 'Unpaid',
      },
      actions: {
        apply_paid_status: 'Apply paid status',
        apply_paid_status_confirm:
          'Are you sure you want to apply paid status for these items?',
        generate_sepa_xml_file: 'Generate XML SEPA file',
        generate_sepa_xml_file_confirm:
          'Are you sure you want to generate XML SEPA file for these items?',
      },
    },
    temperature_readings: {
      name: 'Temperature reading |||| Temperature readings',
      fields: {
        temperature: 'Temperature (°C)',
        createdAt: 'Date',
      },
    },
  },
};
