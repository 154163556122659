import React from 'react';
import { Show, SimpleShowLayout } from 'react-admin';
import * as PropTypes from 'prop-types';
import fields from './fields';

const InvoiceShow = (props) => {
  const { permissions } = props;
  return (
    <Show {...props}>
      <SimpleShowLayout>
        {fields(permissions)}
      </SimpleShowLayout>
    </Show>
  );
};

InvoiceShow.propTypes = {
  permissions: PropTypes.shape({
    roles: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }),
};

InvoiceShow.defaultProps = {
  permissions: null,
};

export default InvoiceShow;
